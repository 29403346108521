import { Card, CardContent, Typography } from "@mui/material";
import { KpiWidgetData } from "../../../types/extraction-dashboard/Widget";
import dashboardThresholdHelper from "../../../helpers/dashboardThresholdHelper";

export type MetricWidgetProps = {
  widget: any;
};

export default function MetricWidget({ widget }: MetricWidgetProps) {
  const data = widget.data as KpiWidgetData;
  const valueColor = dashboardThresholdHelper(data);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Typography variant="h6">{widget.title}</Typography>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" color={valueColor}>
            {data.value}
          </Typography>
          {data?.unit && <Typography variant="h6" color={valueColor}>{data.unit}</Typography>}
        </CardContent>
      </CardContent>
    </Card>
  );
}
