import { useState } from "react";
import { AuthenticationType } from "../../../../types/external-storage/Authentication";
import { StorageType } from "../../../../types/external-storage/StorageType";
import ConfigureAWSKeysStorageForm from "./ConfigureAWSKeysStorageForm";
import ConfigureAWSCrossAccountStorageForm from "./ConfigureAWSCrossAccountStorageForm";
import AWSAuthenticationSelect, { authTypeOptions } from "../AWSAuthenticationSelect";

interface AWSAuthenticationSelectorProps {
  siteId: string;
  id: string;
  setIsOpen: (isOpen: boolean) => void;
  storageType: StorageType;
}

export default function AWSAuthenticationSelector({
  siteId,
  id,
  setIsOpen,
  storageType,
}: AWSAuthenticationSelectorProps) {
  const [selectedAuthType, setSelectedAuthType] = useState<AuthenticationType>(
    AuthenticationType.AWSAccessKeys
  );


  return (
    <AWSAuthenticationSelect
      authTypeOptions={authTypeOptions}
      selectedAuthType={selectedAuthType}
      onChange={setSelectedAuthType}
    >
      {selectedAuthType === AuthenticationType.AWSAccessKeys && (
        <ConfigureAWSKeysStorageForm
          siteId={siteId}
          setIsOpen={setIsOpen}
          id={id}
          storageType={storageType}
        />
      )}
      {selectedAuthType === AuthenticationType.AWSCrossAccount && (
        <ConfigureAWSCrossAccountStorageForm
          siteId={siteId}
          setIsOpen={setIsOpen}
          id={id}
          storageType={storageType}
        />
      )}
    </AWSAuthenticationSelect>
  );
}


