import { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { Download, InsertLink } from "@mui/icons-material";
import Interaction from "../../../../types/interaction/Interaction";
import { useCreateBulkExport } from "../../../../hooks/useBulkExport";
import { CreateBulkExportRequest } from "../../../../api/bulkExport";
import { useAlert } from "../../../../context/AlertContext";
import LoadingIndicator from "../../../../components/common/LoadingIndicator/LoadingIndicator";

interface BulkExportRequestProps {
  customerId: string | undefined;
  interactions: Interaction[];
}

/**
 * Displays a button onto the search page to submit a bulk export request
 * @param customerId the current customer
 * @param interactions a list of selected interactions
 * @returns
 */
export default function BulkExportRequest({
  customerId,
  interactions,
}: BulkExportRequestProps) {
  const [open, setOpen] = useState(false);
  const createBulkExportMutation = useCreateBulkExport();
  const { isPending, isSuccess, reset } = createBulkExportMutation;

  const showAlert = useAlert();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const navigateToBulkExport = () => {
    handleClose();
    window.open(`/customers/${customerId}/jobs/bulk-export`, "_blank");
  };

  /**
   * Submits the API request to the backend
   */
  const submitRequest = async () => {
    const interactionIds = interactions.map(
      (interaction) => interaction.interactionId
    );

    const request: CreateBulkExportRequest = {
      customerId: customerId,
      interactionIds: interactionIds,
    };

    try {
      const jobId = await createBulkExportMutation.mutateAsync(request);
      showAlert(`Export started with Job ID ${jobId}.`, "success");
    } catch (error: unknown) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      } else {
        showAlert("An unexpected error occurred when creating a bulk export.");
      }
    }
  };

  return (
    <div>
      <div>
        <Tooltip
          title={
            interactions.length <= 0
              ? "Select interactions to export from below"
              : ""
          }
          disableHoverListener={interactions.length > 0}
          placement="top"
        >
          <Badge badgeContent={interactions.length} color="info">
            <Button
              variant="contained"
              size="large"
              onClick={handleOpen}
              startIcon={<Download />}
              disabled={interactions.length <= 0}
            >
              Bulk Export
            </Button>
          </Badge>
        </Tooltip>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Bulk Export</DialogTitle>
        <DialogContent>
          {!isSuccess && (
            <DialogContentText sx={{ pb: 2 }}>
              Are you sure you want to export {interactions.length}{" "}
              interactions?
            </DialogContentText>
          )}
          <Alert severity={isSuccess ? "success" : "info"}>
            <Typography>
              {isSuccess
                ? "Successfully started bulk export. To view click button below."
                : "Bulk Export jobs take time to complete."}
            </Typography>
          </Alert>
          {isSuccess && !isPending && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <Button
                onClick={navigateToBulkExport}
                variant="contained"
                color="primary"
                startIcon={<InsertLink />}
              >
                Go to Bulk Exports
              </Button>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={submitRequest}
            variant="contained"
            color="primary"
            disabled={isSuccess}
          >
            Confirm
          </Button>
        </DialogActions>

        {isPending && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 1000,
            }}
          >
            <LoadingIndicator />
          </div>
        )}
      </Dialog>
    </div>
  );
}
