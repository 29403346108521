import { Job, JobState, JobType } from "../types/job/Job";
import { getApi } from "./helpers/apiMethods";

interface GetJobsRequest {
  customerId: string | undefined;
  jobType: JobType;
}

interface GetJobsResponse {
  jobs: Job[];
}

interface GetJobStatusRequest {
  customerId: string | undefined;
  jobId: string | undefined;
}

interface GetJobStatusResponse {
  jobId: string;
  jobState: JobState;
  outputLocation: string;
}

/**
 * Gets jobs given a job type
 * @param customerId the customer to search the records for
 * @param jobType a JobType object
 * @returns a list of jobs
 */
export const getJobs = async ({
  customerId,
  jobType,
}: GetJobsRequest): Promise<Job[]> => {
  const response = await getApi<GetJobsResponse>(
    `/v1/customers/${customerId}/jobs/${jobType}`
  );
  return response.data?.jobs ?? [];
};

export const getTranscodingJobStatus = async ({
  customerId,
  jobId,
}: GetJobStatusRequest): Promise<JobState | undefined> => {
  const response = await getApi<GetJobStatusResponse>(
    `/v1/customers/${customerId}/jobs/transcoding/${jobId}`
  );

  return response.data?.jobState;
};
