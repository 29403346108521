import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { ReactNode, useEffect } from "react";
import Draggable from 'react-draggable';

interface DialogFormState {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isSubmitting: boolean;
  isSubmitDisabled?: boolean;
  isCancelDisabled?: boolean;
}

interface DialogFormProps extends DialogFormState {
  formId: string;
  dialogTitle: string;
  draggable?: boolean;
  children?: ReactNode;
  beforeClose?: () => void;
  afterClose?: () => void;
  submitText?: string;
}

// draggable dialog from MUI
function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef<HTMLDivElement>(null);
  return (
    <Draggable
      nodeRef={nodeRef as React.RefObject<HTMLDivElement>}
      handle="#dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} ref={nodeRef} />
    </Draggable>
  );
}

const DialogForm = ({
  formId,
  dialogTitle,
  isOpen,
  draggable = false,
  setIsOpen,
  beforeClose,
  afterClose,
  isSubmitting,
  isSubmitDisabled = false,
  isCancelDisabled = false,
  submitText = "Submit",
  children,
}: DialogFormProps) => {
  useEffect(() => {
    if (!isOpen && afterClose) {
      afterClose();
    }
  }, [afterClose, isOpen]);

  const handleClose = () => {
    beforeClose?.();
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="sm"
      fullWidth
      PaperComponent={ draggable ? PaperComponent: Paper }
    >
      <DialogTitle id="dialog-title" style={ draggable ? { cursor: 'move' } : {}}>
      <Typography variant="h6">{dialogTitle}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
  
      <DialogContent
        id="dialog-description"
        sx={{ paddingTop: "0.5rem !important" }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={isSubmitting || isCancelDisabled}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form={formId}
          disabled={isSubmitting || isSubmitDisabled}
          variant="contained"
        >
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogForm;
