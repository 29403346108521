import { Card, CardContent, Typography, useTheme } from "@mui/material";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";

export type WidgetData = {
  chartType: string;
  labels: string[];
  values: number[];
};

interface BarChartProps {
  widget: any;
}

const BarChart = ({ widget }: BarChartProps) => {
  const [chartData, setChartData] = useState<{
    categories: string[];
    series: number[];
  }>({
    categories: [],
    series: [],
  });

  const theme = useTheme();
  const { labels, values } = widget.data;

  useEffect(() => {
    setChartData({
      categories: labels,
      series: values,
    });
  }, [widget, labels, values]);

  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData.categories,
    },

    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    colors: [theme.palette.customColors?.wDarkBlue],
    fill: {
      opacity: 1,
    }
  };

  const series = [
    {
      name: widget.title,
      data: chartData.series,
    },
  ];

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6">{widget.title}</Typography>
        <Chart options={options} series={series} type="bar"  />
      </CardContent>
    </Card>
  );
};

export default BarChart;
