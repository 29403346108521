import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import UserAuth from "../../../types/user/UserAuth";

export interface ViewsPermissionWrapperProps extends PropsWithChildren{
user: UserAuth | null;
resource: string;
requiredPermission: string;
}

export default function ViewsPermissionWrapper ({
  user,
  resource,
  requiredPermission,
  children
}: ViewsPermissionWrapperProps) {

  if (!user) {
    return <Box>You are not authorized to view this content.</Box>
  }
  if (!user.hasPermission(`${resource}:${requiredPermission}`)) {
    return <Box>You are not authorized to view this content.</Box>
  }
  return (
    <Box>
      {children}
    </Box>
  )
}