import { useMutation, useQuery } from "@tanstack/react-query";
import {
  FileUrlResponse,
  FieldValue,
  GetInteractionsRequest,
  GetInteractionsResponse,
  getFileUrl,
  getSuggestedFieldValues,
  getInteractions,
  updateInteraction,
  getInteractionMetadata,
  fetchChatTranscript,
  fetchEmailTranscript,
  UpdateInteractionTagsRequest,
  updateInteractionTags,
  getInteractionTags,
  UpdateInteractionViewsRequest,
  updateInteractionViews
} from "../api/interaction";
import {
  DownloadInteractionFileRequest,
  downloadInteractionFile,
} from "../helpers/fileDownload";
import { ChatMessage } from "../types/chat/chat";
import { Email } from "../types/email/email";

/* istanbul ignore next */
export const useFileUrl = (
  interactionId?: string,
  customerId?: string,
  ready: boolean = true
) => {
  return useQuery<FileUrlResponse | undefined, Error>({
    queryKey: ["fileUrl", interactionId, customerId, ready],
    queryFn: () => getFileUrl(interactionId!, customerId),
    enabled: !!interactionId && !!customerId && ready,
  });
};

/* istanbul ignore next */
export const useChatTranscript = (fileUrl?: string) => {
  return useQuery<ChatMessage[], Error>({
    queryKey: ["chatTranscript", fileUrl],
    queryFn: () => fetchChatTranscript(fileUrl!),
    enabled: !!fileUrl,
  });
};

/* istanbul ignore next */
export const useEmailTranscript = (fileUrl?: string) => {
  return useQuery<Email, Error>({
    queryKey: ["emailTranscript", fileUrl],
    queryFn: () => fetchEmailTranscript(fileUrl!),
    enabled: !!fileUrl,
  })
}

export const useInteractionFileDownload = () => {
  return useMutation<void, Error, DownloadInteractionFileRequest>({
    mutationKey: ["downloadInteractionFile"],
    mutationFn: downloadInteractionFile,
  });
};

/* istanbul ignore next */
export const useInteractions = (
  request?: GetInteractionsRequest,
  customerId?: string
) => {
  return useQuery<GetInteractionsResponse, Error>({
    queryKey: ["interactions", customerId, request],
    queryFn: () => getInteractions(customerId, request!),
    enabled: !!customerId || !!request?.siteId,
  });
};

export const useInteractionTags = (
  customerId?: string,
) => {
  return useQuery<string[], Error>({
    queryKey: ["interactionTags", customerId],
    queryFn: () => getInteractionTags(customerId),
    enabled: !!customerId,
  });
}

/* istanbul ignore next */
export const useTypeaheadSearch = (
  fieldName?: string,
  pascalFieldName?: string,
  value?: string,
  customerId?: string
) => {
  return useQuery<FieldValue[], Error>({
    queryKey: [
      "getSuggestedFieldValues",
      fieldName,
      pascalFieldName,
      value,
      customerId,
    ],
    queryFn: () =>
      getSuggestedFieldValues({
        customerId,
        fieldName: fieldName!,
        pascalFieldName: pascalFieldName!,
        value: value!,
      }),
    enabled:
      !!customerId &&
      !!fieldName &&
      !!pascalFieldName &&
      !!value &&
      value.length >= 3,
    retryDelay: 1000,
  });
};

/* istanbul ignore next */
export const useUpdateInteraction = () => {
  return useMutation({
    mutationKey: ["updateInteraction"],
    mutationFn: updateInteraction,
  });
};

/* istanbul ignore next */
export const useUpdateInteractionTags = () => {
  return useMutation({
    mutationKey: ["updateInteractionTags"],
    mutationFn: (request: UpdateInteractionTagsRequest) => updateInteractionTags(request),
  });
};

/* istanbul ignore next */
export const useUpdateInteractionViews = () => {
  return useMutation({
    mutationKey: ["updateInteractionViews"],
    mutationFn: (request: UpdateInteractionViewsRequest) => updateInteractionViews(request),
  })
}

/* istanbul ignore next */
export const useInteractionMetadata = (
  customerId: string,
  interactionId: string
) => {
  return useQuery<Record<string, unknown>, Error>({
    queryKey: ["interactionMetadata", customerId, interactionId],
    queryFn: () => getInteractionMetadata({ customerId, interactionId }),
    enabled: !!customerId && !!interactionId,
  });
};
