import React, { useState, useEffect } from "react";
import { Control } from "react-hook-form";
import {
  Autocomplete,
  Chip,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";
import View from "../../../types/view/View";

export type ViewsSelectProps = {
  control: Control<any>;
  setViewsInput: React.Dispatch<React.SetStateAction<string>>;
  viewsInput: string;
  filteredViewsAvailable: View[];
  isViewsLoading: boolean;
  currentViews: string[];
  allAvailableViews: View[];
  onChange: (selectedViews: string[]) => void;
};

export default function ViewsSelect({
  control,
  viewsInput,
  setViewsInput,
  filteredViewsAvailable,
  isViewsLoading,
  allAvailableViews,
  currentViews,
  onChange,
}: ViewsSelectProps) {
  const [viewIds, setViewIds] = useState<string[]>(currentViews);

  useEffect(() => {
    setViewIds(currentViews);
  }, [currentViews]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: View[]) => {
    const updatedViewIds = newValue.map((view) => view.viewId);
    setViewIds(updatedViewIds);
    onChange(updatedViewIds);
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        id="views-autocomplete"
        options={filteredViewsAvailable}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.viewId === value.viewId}
        value={allAvailableViews.filter((view) =>
          viewIds.includes(view.viewId)
        )}
        onChange={handleChange}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              onDelete={() => {
                const updatedViewIds = viewIds.filter(
                  (viewId) => viewId !== option.viewId
                );
                setViewIds(updatedViewIds);
                onChange(updatedViewIds);
              }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Views"
            placeholder="Select views"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isViewsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}
