import ExistingStorageConfigurationDisplay from "../../components/external-storage/ExistingStorageConfigurationDisplay";
import StorageTypeSelector from "../../components/external-storage/StorageTypeSelector";
import CustomTabPanel from "../../components/common/CustomPanel/CustomTabPanel";
import { StorageConfigModel } from "../../types/site/Site";

interface EditStoragePanelProps {
  selectedTab: number;
  storageConfig: StorageConfigModel | null;
  siteId: string;
  id: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
/**
 * panel displays:
 * storage config if site already has external storage, no changes available at this time
 * storage config form if no external storage is configured
 */
export default function StoragePanel({
  selectedTab,
  storageConfig,
  siteId,
  id,
  isOpen,
  setIsOpen,
}: EditStoragePanelProps) {
  return (
    <CustomTabPanel index={1} value={selectedTab}>
      {storageConfig ? (
        <ExistingStorageConfigurationDisplay
          storageConfig={storageConfig}
          siteId={siteId}
          setIsOpen={setIsOpen}
        />
      ) : (
        <StorageTypeSelector id={id} siteId={siteId} setIsOpen={setIsOpen} />
      )}
    </CustomTabPanel>
  );
}
