import Role from "../../types/role/Role";
import { SyntheticEvent } from "react";
import { Controller, Control } from "react-hook-form";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FormGroup,
  FormHelperText,
  TextField,
} from "@mui/material";

export type RolesSelectProps = {
  control: Control<any>;
  roles: Role[] | undefined;
  isRolesLoading: boolean;
  error: string | undefined;
  defaultValue: Role | null;
};

export default function RolesSelect({
  control,
  roles,
  isRolesLoading,
  error,
  defaultValue,
}: RolesSelectProps) {
  return (
    <FormGroup>
      <Controller
        name="role"
        control={control}
        render={({ field: { ref: roleRef, ...field } }) => (
          <Autocomplete
            {...field}
            id="role"
            options={roles ?? []}
            onChange={(_: SyntheticEvent, data: Role | null) => {
              field.onChange(data);
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                name="role"
                label="Role"
                error={!!error}
                inputRef={roleRef}
                required
              />
            )}
            getOptionLabel={(option: Role) => option.name}
            isOptionEqualToValue={(option: Role, value: Role) => {
              return option.roleId === value.roleId;
            }}
            loading={isRolesLoading}
          />
        )}
        defaultValue={defaultValue}
        disabled={isRolesLoading}
        rules={{ required: "Role is required." }}
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormGroup>
  );
}
