import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Box,
  Alert,
} from "@mui/material";
import SitePanel from "./SitePanel";
import StoragePanel from "./StoragePanel";
import { StorageConfigModel } from "../../types/site/Site";

const SITE_FORM_ID = "edit-site-form";
const STORAGE_FORM_ID = "edit-storage-form";

export type EditSiteFormValues = {
  name: string;
  storageConfig: StorageConfigModel | null;
};

export type CreateSiteFormValues = {
  name: string;
};

export type SiteDialogProps = {
  isOpen: boolean;
  action: "Create" | "Edit";
  setIsOpen: (isOpen: boolean) => void;
  createSite: (data: CreateSiteFormValues) => void;
  editSite: (data: EditSiteFormValues) => void;
  siteId: string;
  formValues?: EditSiteFormValues | CreateSiteFormValues;
};

const SiteDialog = ({
  action,
  isOpen,
  setIsOpen,
  createSite,
  editSite,
  formValues,
  siteId,
}: SiteDialogProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{action} Site</DialogTitle>
      <DialogContent>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Edit Site and Storage"
        >
          <Tab label="Site" value={0} />
          <Tab label="Storage" value={1} />
        </Tabs>
        {selectedTab === 0 && (
          <SitePanel
            id={SITE_FORM_ID}
            action={action}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            createSite={createSite}
            editSite={editSite}
            formValues={formValues}
            selectedTab={selectedTab}
          />
        )}
        {selectedTab === 1 && action === "Edit" && (
          <StoragePanel
            storageConfig={(formValues as EditSiteFormValues).storageConfig}
            id={STORAGE_FORM_ID}
            selectedTab={selectedTab}
            siteId={siteId}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
        {selectedTab === 1 && action === "Create" && (
          <Box margin={2}>
            <Alert severity="info">
              Cannot configure storage for a new site before creating the site.
            </Alert>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SiteDialog;
