export type Job = {
  customerId: string;
  jobId: string;
  state: JobState;
  jobType: JobType;
  startTime: string;
  endTime: string;
  recordsProcessed: number;
  input?: string;
  outputLocation?: string;
};

export enum JobState {
  InProgress = "InProgress",
  Complete = "Complete",
  Error = "Error",
}

export enum JobType {
  CXone = "cxone",
  Five9 = "five9",
  AmazonConnect = "amazon-connect",
  BulkExport = "bulk-export",
  Transcode = "transcode",
}
