import { useQuery } from "@tanstack/react-query";
import { getExtractionDashboard } from "../api/extractionDashboard";
import { ExtractionDashboard } from "../types/extraction-dashboard/ExtractionDashboard";

export function useExtractionDashboard(
  customerId: string | undefined
) {
  return useQuery<ExtractionDashboard | undefined>({
    queryKey: ["customer", customerId],
    queryFn: () => getExtractionDashboard(customerId!),
    enabled: !!customerId
  })
}