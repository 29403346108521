export interface IPermissions {
  auditLogPermissions: string[];
  interactionPermissions: string[];
  rolePermissions: string[];
  sitePermissions: string[];
  userPermissions: string[];
  connectorPermissions: string[];
  customersPermissions: string[];
  viewPermissions: string[];
}

export default class Permissions implements IPermissions {
  public auditLogPermissions: string[] = [];
  public interactionPermissions: string[] = [];
  public rolePermissions: string[] = [];
  public sitePermissions: string[] = [];
  public userPermissions: string[] = [];
  public connectorPermissions: string[] = [];
  public customersPermissions: string[] = [];
  public viewPermissions: string[] = [];

  public static fromIPermissions(data: IPermissions | undefined): Permissions {
    if (!data) {
      return new Permissions();
    }
    const permissions = new Permissions();
    permissions.auditLogPermissions = data.auditLogPermissions;
    permissions.interactionPermissions = data.interactionPermissions;
    permissions.rolePermissions = data.rolePermissions;
    permissions.sitePermissions = data.sitePermissions;
    permissions.userPermissions = data.userPermissions;
    permissions.connectorPermissions = data.connectorPermissions;
    permissions.customersPermissions = data.customersPermissions;
    permissions.viewPermissions = data.viewPermissions;
    return permissions;
  }

  public get allPermissions(): string[] {
    return [
      ...this.auditLogPermissions,
      ...this.interactionPermissions,
      ...this.rolePermissions,
      ...this.sitePermissions,
      ...this.userPermissions,
      ...this.connectorPermissions,
      ...this.customersPermissions,
      ...this.viewPermissions
    ];
  }
}
