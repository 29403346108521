import { useState, useEffect } from "react";
import sampleData from "./sample-data.json";
import { Box, Typography, Grid } from "@mui/material";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";
import { WidgetType } from "../../types/extraction-dashboard/WidgetType";
import MetricWidget from "./components/MetricWidget";
import TextWidget from "./components/TextWidget";
import SpeedometerWidget from "./components/SpeedometerWidget";
import TableWidget from "./components/TableWidget";
import { useExtractionDashboard } from "../../hooks/useExtractionDashboard";
import { useParams } from "react-router-dom";
import ChartWidget from "./components/ChartWidget";
import dayjs from "dayjs";

export default function ExtractionDashboard() {
  const [dashboardData, setDashboardData] = useState<any>();
  const { customerId } = useParams();
  const { data, isLoading } = useExtractionDashboard(customerId);

  useEffect(() => {
    setDashboardData(sampleData);
  }, [data]);

  const renderWidget = (widget: any, index: number) => {
    switch (widget.type) {
      case WidgetType.Chart:
        return <ChartWidget widget={widget} key={index} />;
      case WidgetType.Kpi:
        return <MetricWidget widget={widget} key={index} />;
      case WidgetType.Table:
        return <TableWidget widget={widget} key={index} />;
      case WidgetType.Text:
        return <TextWidget widget={widget} key={index} />;
      case WidgetType.Progress:
        return <SpeedometerWidget widget={widget} key={index} />
    }
  };

  const widgetSize = {
    full: {
      xs: 12
    },
    large: {
      xs: 12,
      md: 8,
      lg: 6,
    },
    small: {
      xs: 12,
      md: 4,
      lg: 3,
    }
  }
  const getWidgetWidth = (widgetType: WidgetType) => {
    switch (widgetType) {
      case WidgetType.Chart:
        return widgetSize.small;
      case WidgetType.Kpi:
        return widgetSize.small;
      case WidgetType.Table:
        return widgetSize.large;
      case WidgetType.Text:
        return widgetSize.small;
      case WidgetType.Progress:
        return widgetSize.small;
      default:
        return {};
    }
  };

  return (
    <div className="h-full w-full p-4">
      <div className="rounded-md  p-2 mb-4 shadow-md">
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{
          fontWeight: 600
        }}
      >
        {dashboardData ? dashboardData.title : "Loading Dashboard..."}
      </Typography>
      <Typography variant="h6" gutterBottom textAlign="center">
        {dashboardData
          ? `Last Updated: ${dayjs(dashboardData.lastUpdated).toString()}`
          : null}
      </Typography>
      </div>
      {isLoading && (
        <Box textAlign="center">
          <LoadingIndicator />
        </Box>
      )}
      {!isLoading && (
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "flex-start",
              alignItems: "stretch",
            }}
          >
            {dashboardData.widgets.map((widget: any, index: number) => {
              return (
                <Grid item key={index} {...getWidgetWidth(widget.type)}>
                  {renderWidget(widget, index)}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </div>
  );
}
