export enum InteractionType {
  Audio = "Audio",
  Chat = "Chat",
  Email = "Email",
  Video = "Video",
  AudioAndVideo = "AudioAndVideo",
}

export type InteractionDirection = "in" | "out";

export const normalizeMediaType = (input: string): InteractionType | null => {
  switch (input) {
    case "Audio":
    case "0":
      return InteractionType.Audio;
    case "Chat":
    case "1":
      return InteractionType.Chat;
    case "Email":
    case "2":
      return InteractionType.Email;
    case "Video":
    case "3":
      return InteractionType.Video;
    case "AudioAndVideo":
    case "4":
      return InteractionType.AudioAndVideo;
    default:
      return InteractionType.Audio;
  }
};

type Interaction = {
  customerId: string;
  siteId: string;
  interactionId: string;
  masterInteractionId?: string;
  archivePath: string;
  interactionType: InteractionType;
  fileName: string;
  startTime: string;
  endTime: string;
  legalHold: boolean;
  hasMedia: boolean;
  retentionDate: number | null;
  retentionDateHold: number | null;
  importTimestamp: string;
  filesizeBytes?: number;
  sourceId?: string;
  agentId?: string;
  agentName?: string;
  durationMs?: number;
  phoneNumber?: string;
  dnis?: string;
  extension?: string;
  group?: string;
  direction?: InteractionDirection;
  device?: string;
  participants?: string[];
  segmentIds?: string[];
  tags: string[];
  views: string[];
  codec: Codec;
};

export enum Codec {
  GSM0610 = "GSM0610",
}
const transcribeableCodecs: Codec[] = [];

export const getRetentionDate = (interaction: Interaction): number | null => {
  return interaction.legalHold
    ? interaction.retentionDateHold
    : interaction.retentionDate;
};

export const setRetentionDate = (
  interaction: Interaction,
  retentionDate: number | null
) => {
  if (interaction.legalHold) {
    interaction.retentionDateHold = retentionDate;
    interaction.retentionDate = null;
  } else {
    interaction.retentionDate = retentionDate;
    interaction.retentionDateHold = null;
  }
};

export const setLegalHold = (interaction: Interaction, legalHold: boolean) => {
  interaction.legalHold = legalHold;
  if (interaction.legalHold) {
    interaction.retentionDateHold = interaction.retentionDate;
    interaction.retentionDate = null;
  } else {
    interaction.retentionDate = interaction.retentionDateHold;
    interaction.retentionDateHold = null;
  }
};

export const setTags = (interaction: Interaction, tags: string[]) => {
  interaction.tags = tags;
};

export function canTranscribe(
  codec: Codec | undefined,
  interactionType: InteractionType,
  hasMedia: boolean
): boolean {
  return (
    (!codec || transcribeableCodecs.includes(codec)) &&
    hasMedia &&
    interactionType === InteractionType.Audio
  );
}

export default Interaction;
