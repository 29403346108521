import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Divider,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AdminInfoContext } from "../../context/AdminInfoContext";
import { useAlert } from "../../context/AlertContext";
import { AuthContext } from "../../context/AuthContext";
import { isAdmin } from "../../helpers/authHelpers";
import PermissionMenuItem from "./PermissionMenuItem";
import { useTheme } from "@mui/material/styles";
import logo from "../../images/LogoMenuBar.png";

const getCustomerScopedPath = (
  customerIdFromPath?: string,
  customerIdFromUser?: string
) => {
  if (customerIdFromPath) {
    return `/customers/${customerIdFromPath}`;
  }
  return `/customers/${customerIdFromUser}`;
};

export default function MenuBar() {
  const { customerId: customerIdFromPath } = useParams();
  const { authenticated, user } = useContext(AuthContext);
  const customerIdFromUser = user?.customerId;
  const customerScopedPath = getCustomerScopedPath(
    customerIdFromPath,
    customerIdFromUser
  );

  const theme = useTheme();

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
        }}
      >
        <Toolbar>
          <Box>
            <img
              src={logo}
              alt="logo"
              loading="lazy"
              style={{
                maxHeight: 45,
                maxWidth: 45,
                width: "auto",
                height: "auto",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              paddingLeft: 1,
            }}
          >
            <Link
              to={authenticated ? customerScopedPath : "/login"}
              style={{
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
            >
              Continuity Replay
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              color: theme.palette.primary.main,
              display: { xs: "none", md: "flex" },
            }}
          >
            <PermissionMenuItem
              path={`${customerScopedPath}/dashboard`}
              user={user}
              resource="interactions"
              requiredPermission="Read"
            >
              Dashboard
            </PermissionMenuItem>
            <PermissionMenuItem
              path={`${customerScopedPath}/search`}
              user={user}
              resource="interactions"
              requiredPermission="Read"
            >
              Search
            </PermissionMenuItem>
            <Divider orientation="vertical" flexItem />
            <AccountIcon customerScopedPath={customerScopedPath} />
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}

export interface AccountIconProps {
  customerScopedPath: string;
}

const AccountIcon = ({ customerScopedPath }: AccountIconProps) => {
  const { isVisible: isAdminInfoVisible, setVisible: setAdminInfoVisible } =
    useContext(AdminInfoContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const showAlert = useAlert();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { authenticated, user, logout } = useContext(AuthContext);
  const theme = useTheme();

  const handleResetPassword = () => {
    navigateTo("/reset-password");
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
    } catch (error: unknown) {
      if (!(error instanceof Error)) return;
      showAlert(error.message, "error");
    }
  };

  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleInfoBarToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setAdminInfoVisible(checked);
  };

  return authenticated ? (
    <div>
      <IconButton
        size="large"
        edge="end"
        sx={{
          color: theme.palette.primary.main,
        }}
        aria-label="menu"
        onClick={handleClick}
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <PermissionMenuItem
          path={`${customerScopedPath}/sites`}
          close={handleClose}
          user={user}
          resource="sites"
          requiredPermission="Read"
        >
          Sites
        </PermissionMenuItem>
        <PermissionMenuItem
          path={`${customerScopedPath}/users`}
          close={handleClose}
          user={user}
          resource="users"
          requiredPermission="Read"
        >
          Users
        </PermissionMenuItem>
        <PermissionMenuItem
          path={`${customerScopedPath}/roles`}
          close={handleClose}
          user={user}
          resource="roles"
          requiredPermission="Read"
        >
          Roles
        </PermissionMenuItem>
        <Divider />
        <PermissionMenuItem
          path={`${customerScopedPath}/connectors`}
          close={handleClose}
          user={user}
          resource="connectors"
          requiredPermission="Read"
        >
          Connectors
        </PermissionMenuItem>
        <PermissionMenuItem
          path={`${customerScopedPath}/audit-logs`}
          close={handleClose}
          user={user}
          resource="auditlogs"
          requiredPermission="Read"
        >
          Audit Logs
        </PermissionMenuItem>
        <PermissionMenuItem
          path={`${customerScopedPath}/jobs/bulk-export`}
          close={handleClose}
          user={user}
          resource="interactions"
          requiredPermission="Export"
        >
          Bulk Export
        </PermissionMenuItem>
        <PermissionMenuItem
          path={`${customerScopedPath}/views`}
          close={handleClose}
          user={user}
          resource="views"
          requiredPermission="*"
        >
          Manage Views
        </PermissionMenuItem>
        <Divider />
        <PermissionMenuItem
          path={`${customerScopedPath}/customization/set-logo`}
          close={handleClose}
          user={user}
          resource="customization"
          requiredPermission="Update"
        >
          Set Logo
        </PermissionMenuItem>
        <PermissionMenuItem
          path={`${customerScopedPath}/header-names`}
          close={handleClose}
          user={user}
          resource="customers"
          requiredPermission="Update"
        >
          Configure Table Headers
        </PermissionMenuItem>
        <Divider />
        {isAdmin(user) && (
          <div>
            <PermissionMenuItem
              path="/federated-auth"
              close={handleClose}
              user={user}
              resource="customization"
              requiredPermission="Read"
            >
              Federated Auth
            </PermissionMenuItem>
            <MenuItem onClick={() => navigateTo("/customers/admin")}>
              Add/Change Customer
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAdminInfoVisible}
                    onChange={handleInfoBarToggle}
                  />
                }
                label="Show Info Bar"
              />
            </MenuItem>
            <Divider />
          </div>
        )}
        <MenuItem onClick={handleResetPassword}>Reset Password</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  ) : null;
};
