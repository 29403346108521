import { useState } from "react";
import { useFileUrl, useInteractionMetadata } from "../../hooks/useInteraction";
import { useParams } from "react-router-dom";
import {
  canTranscribe,
  Codec,
  InteractionType,
  normalizeMediaType,
} from "../../types/interaction/Interaction";
import { Box } from "@mui/material";
import MediaDisplaySelector from "./MediaDisplaySelector";
import ClosePlayerButton from "./ButtonsAndControls/ClosePlayerButton";
import { TranscriptionView } from "../transcription/TranscriptionView";

const NewWindowMediaPlayer = () => {
  const {
    customerId,
    playerInteractionId,
    mediaType,
  } = useParams();
  const { data, isLoading } = useFileUrl(playerInteractionId, customerId);
  const [isPlaying, setIsPlaying] = useState(false);
  const { data: metadata } = useInteractionMetadata(
    customerId!,
    playerInteractionId!
  );

  const agentName = (metadata as {agentName?: string})?.agentName;
  const phoneNumber = (metadata as { phoneNumber?: string })?.phoneNumber;
  const startTime = (metadata as { startTime?: string })?.startTime;
  const endTime = (metadata as { endTime?: string })?.endTime;

  const closeWindow = () => {
    window.close();
  };

  const normalizedType = normalizeMediaType(mediaType!) as InteractionType;

  return (
    <Box className='flex flex-col overflow-y-auto'>
      <div className="text-center font-bold text-xl">
        {agentName}{"  "}{phoneNumber}
      </div>

      <Box className="w-full text-center flex flex-wrap justify-center pb-2">
        {startTime && new Date(startTime!).toLocaleString()}{" - "}{endTime && new Date(endTime!).toLocaleString()}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start", marginBottom: 1 }}>
        <ClosePlayerButton closePlayer={closeWindow} />
      </Box>
      <MediaDisplaySelector
        interactionType={normalizedType}
        playerInteractionId={playerInteractionId}
        data={data}
        agentId={metadata?.agentId as string}
        isLoading={isLoading}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        metadata={metadata}
      />
      {canTranscribe(metadata?.codec as Codec | undefined, normalizedType, Boolean(metadata?.hasMedia)) && (
        <TranscriptionView className='mt-4' customerId={customerId!} interactionId={playerInteractionId!} />
      )}
    </Box>
  );
};

export default NewWindowMediaPlayer;
