import { Close, Edit } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  PaperProps,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Interaction, {
  getRetentionDate,
} from "../../../types/interaction/Interaction";
import { Controller, useForm } from "react-hook-form";
import FieldLabelContext from "../context/FieldLabelContext";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import {
  useInteractionTags,
  useUpdateInteractionViews,
} from "../../../hooks/useInteraction";
import LoadingIndicator from "../../../components/common/LoadingIndicator/LoadingIndicator";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useAlert } from "../../../context/AlertContext";
import {
  updateInteraction,
  UpdateInteractionRequest,
  updateInteractionTags,
  UpdateInteractionTagsRequest,
} from "../../../api/interaction";
import { AxiosError } from "axios";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Draggable from "react-draggable";
import ViewsSelect from "../../views/components/ViewsSelect";
import { useViews } from "../../../hooks/useView";
import ViewsPermissionWrapper from "../../views/components/ViewsPermissionWrapper";
import { AuthContext } from "../../../context/AuthContext";

const FORM_ID = "edit_interaction_id";

export interface EditInteractionProps {
  row: Interaction;
  customerId: string;
  setHighlight: React.Dispatch<React.SetStateAction<string>>;
  apiRef: MutableRefObject<GridApiCommunity>;
}

export interface RetentionMessageProps {
  newRetention?: number | null;
  oldRetention?: number | null;
}

/****** To build a tab panel within the dialog *******/
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/** Taken from MUI */
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

/** Taken from MUI */
function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

// draggable dialog from MUI
function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef<HTMLDivElement>(null);
  return (
    <Draggable
      nodeRef={nodeRef as React.RefObject<HTMLDivElement>}
      handle="#dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} ref={nodeRef} />
    </Draggable>
  );
}

const schema = z.object({
  interactionId: z.string(),
  retentionDate: z.union([
    z.number(), // Valid future timestamp
    z.null(), // Allows null values
  ]),
  legalHold: z.boolean(),
  tags: z.array(z.string()).optional(),
  views: z.array(z.string()).optional(),
});

/**
 * Displays a dialog that allows a user to edit an interaction
 * @param row the current interaction
 * @constructor
 */
export default function EditInteractionModal({
  row,
  customerId,
  setHighlight,
  apiRef,
}: EditInteractionProps) {
  // handle opening and closing the dialog
  const [open, setOpen] = useState(false);
  const [viewsInput, setViewsInput] = useState<string>("");
  // for tags
  const [inputValue, setInputValue] = useState<string>("");
  // Tab control
  const [tabValue, setTabValue] = useState(0);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { user } = useContext(AuthContext);
  const updateInteractionViewsMutation = useUpdateInteractionViews();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
    // highlight selected row
    setHighlight(row.interactionId);
  };
  const handleClose = () => {
    setHighlight("");
    setTabValue(0);
    setOpen(false);
    setIsConfirmed(false);
    reset();
  };

  const showAlert = useAlert();

  const { getFieldLabel } = useContext(FieldLabelContext);

  //set up form
  const form = useForm<Interaction>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      ...row,
      retentionDate: getRetentionDate(row),
    },
  });

  const { control, formState, reset, handleSubmit, watch } = form;
  const { isSubmitting, errors, isValid, isDirty } = formState;

  // for custom logic
  const onHold = watch("legalHold");
  const currentTags = watch("tags");
  const currentViews = watch("views");

  const { data: allAvailableTags = [] } = useInteractionTags(customerId);
  const { data: allAvailableViews = [], isLoading: isViewsLoading } =
    useViews(customerId);

  const filteredViewsAvailable = useMemo(() => {
    return allAvailableViews.filter((v) => !currentViews?.includes(v.viewId));
  }, [allAvailableViews, currentViews]);

  // remove already present tags
  const filteredOptions = useMemo(() => {
    return allAvailableTags.filter((tag) => !currentTags.includes(tag));
  }, [allAvailableTags, currentTags]);

  useEffect(() => {
    if (!isDirty) {
      setIsConfirmed(false);
    }
  }, [isDirty]);

  /** Checks error and alerts user*/
  const processRowUpdateErrors = (error: unknown) => {
    if (error instanceof AxiosError) {
      const backendErrors = error.response?.data?.errors;

      // handle tag errors
      if (backendErrors["tags"] && Array.isArray(backendErrors["tags"])) {
        const errorMessage = backendErrors["tags"].join(" ");
        showAlert(errorMessage, "error");
      }
      // Handle retention date and legal hold errors
      else if (backendErrors["update.RetentionDate"]) {
        const errorMessage = backendErrors["update.RetentionDate"].join(" ");
        showAlert(errorMessage, "error");
      } else if (backendErrors["update"]) {
        const errorMessage = backendErrors["update"].join(" ");
        showAlert(errorMessage, "error");
      } else {
        showAlert("Failed to update interaction. Please try again.", "error");
      }
    } else {
      console.error(error);
      showAlert(
        "An unexpected error occurred when updating the interaction. Please try again.",
        "error"
      );
    }
  };
  /**
   * Handle submission of edited interaction and cleans up form before submitting
   */
  const submitChanges = async (formData: Interaction) => {
    // set up updated row
    const newRow: Interaction = { ...formData };
    if (formData.legalHold) {
      newRow.retentionDateHold = newRow.retentionDate;
      newRow.retentionDate = null;
    } else {
      newRow.retentionDateHold = null;
    }

    // logic
    const didLegalHoldChange = newRow.legalHold !== row.legalHold;
    const didRetentionDateChange =
      getRetentionDate(newRow) !== getRetentionDate(row);
    const didTagsChange =
      newRow.tags.length !== row.tags.length ||
      !newRow.tags.every((tag) => row.tags.includes(tag));
    const didViewsChange =
      newRow.views.length !== row.tags.length ||
      !newRow.views?.every((view) => row.views?.includes(view));
    const interactionId = newRow.interactionId;
    const newLegalHold = didLegalHoldChange ? newRow.legalHold : undefined;
    const newRetentionDate = didRetentionDateChange
      ? getRetentionDate(newRow) ?? 0
      : null;

    if (
      !didRetentionDateChange &&
      !didLegalHoldChange &&
      !didTagsChange &&
      !didViewsChange
    ) {
      return;
    }

    try {
      //update tags
      if (didTagsChange) {
        const request: UpdateInteractionTagsRequest = {
          customerId,
          interactionId: newRow.interactionId,
          newTags: newRow.tags,
        };
        const interaction = await updateInteractionTags(request);
        if (!interaction) {
          throw new Error("Failed to update interaction.");
        }
        apiRef.current.updateRows([
          {
            interactionId,
            tags: interaction.tags,
          },
        ]);
      }

      if (didViewsChange) {
        const interaction = await updateInteractionViewsMutation.mutateAsync({
          customerId: customerId,
          interactionId,
          newViews: newRow.views,
        });
        if (!interaction) {
          throw new Error("Failed to update interaction.");
        }
        apiRef.current.updateRows([
          {
            interactionId,
            views: interaction.views,
          },
        ]);
      }

      //update interaction
      if (didRetentionDateChange || didLegalHoldChange) {
        const request: UpdateInteractionRequest = {
          customerId,
          interactionId: newRow.interactionId,
          newLegalHold,
          newRetentionDate,
        };

        const interaction = await updateInteraction(request);
        if (!interaction) {
          throw new Error("Failed to update interaction.");
        }

        apiRef.current.updateRows([
          {
            interactionId,
            legalHold: interaction.legalHold,
            retentionDate: interaction.retentionDate,
            retentionDateHold: interaction.retentionDateHold,
          },
        ]);
      }

      showAlert("Interaction updated successfully.", "success");
      reset(newRow);
      setHighlight("");
      setTabValue(0);
      setOpen(false);
    } catch (error: unknown) {
      processRowUpdateErrors(error);
    }
  };

  const confirmSubmit = async (formData: Interaction) => {
    if (!isConfirmed) {
      setIsConfirmed(true);
      return;
    }

    // Proceed with submission
    await submitChanges(formData);
    setIsConfirmed(false); // Reset confirmation state
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="dialog-title" style={{ cursor: "move" }}>
          <Typography variant="h6">Edit Interaction</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent
          id="dialog-description"
          sx={{ paddingTop: "0.5rem !important" }}
        >
          <form id={FORM_ID} onSubmit={handleSubmit(confirmSubmit)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label={getFieldLabel("retentionDate")} {...a11yProps(0)} />
                <Tab label={getFieldLabel("tags")} {...a11yProps(1)} />
                <Tab label="Views" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Stack>
                <Controller
                  name="retentionDate"
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      disabled={onHold}
                      label={getFieldLabel("retentionDate")}
                      minDateTime={dayjs()}
                      slotProps={{
                        textField: {
                          error: !!errors.retentionDate,
                          helperText: errors.retentionDate?.message, // Show error message
                        },
                      }}
                      value={
                        field.value
                          ? dayjs(new Date(Number(field.value) * 1000))
                          : null
                      }
                      views={[
                        "year",
                        "month",
                        "day",
                        "hours",
                        "minutes",
                        "seconds",
                      ]}
                      onChange={(newValue: Dayjs | null) => {
                        field.onChange(
                          newValue?.isValid() ? newValue.unix() : null
                        );
                      }}
                    />
                  )}
                />
                <Controller
                  name="legalHold"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(event) =>
                            field.onChange(event.target.checked)
                          }
                        />
                      }
                      label={getFieldLabel("legalHold")}
                    />
                  )}
                />
              </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    id="tags-autocomplete"
                    options={filteredOptions}
                    value={field.value}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) =>
                      setInputValue(newInputValue)
                    }
                    onChange={(event, newTags) => {
                      field.onChange(newTags);
                    }}
                    onBlur={() => {
                      if (inputValue.trim()) {
                        const newTags = [
                          ...(field.value || []),
                          inputValue.trim(),
                        ];
                        setInputValue("");
                        field.onChange(newTags);
                      }
                    }}
                    renderTags={(tagValues, getTagProps) =>
                      tagValues.map((tag, index) => (
                        <Chip
                          label={tag}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getFieldLabel("tags")}
                        sx={{
                          "& .MuiInputBase-input": {
                            fontSize: "small",
                          },
                        }}
                        placeholder="Type to add tags..."
                      />
                    )}
                    freeSolo
                    disableCloseOnSelect
                  />
                )}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <ViewsPermissionWrapper
                user={user}
                resource="views"
                requiredPermission="Update"
              >
                <Controller
                  name="views"
                  control={control}
                  render={(field) => {
                    return (
                      <ViewsSelect
                        control={control}
                        viewsInput={viewsInput}
                        setViewsInput={setViewsInput}
                        filteredViewsAvailable={filteredViewsAvailable}
                        allAvailableViews={allAvailableViews}
                        isViewsLoading={isViewsLoading}
                        currentViews={currentViews}
                        onChange={(selectedViews) =>
                          field.field.onChange(selectedViews)
                        }
                      />
                    );
                  }}
                />
              </ViewsPermissionWrapper>
            </CustomTabPanel>
          </form>
          {isConfirmed && (
            <Alert severity="warning">
              Are you sure you want to edit this interaction?
            </Alert>
          )}
          {isSubmitting && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                zIndex: 1000,
              }}
            >
              <LoadingIndicator />
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            disabled={isSubmitting}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form={FORM_ID}
            disabled={isSubmitting || !isValid || !isDirty}
            variant="contained"
          >
            {isConfirmed ? "Click again to confirm" : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

      <Tooltip title="Edit interaction">
        <IconButton aria-label="open-interaction-edit" onClick={handleOpen}>
          <Edit />
        </IconButton>
      </Tooltip>
    </>
  );
}
