export const convertBytesToReadable = (bytes: number, si = false, dp = 1) => {
  const threshold = si ? 1000 : 1024;

  if (Math.abs(bytes) < threshold) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= threshold;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= threshold &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

export const convertMsToTimeDisplay = (durationMs?: number) => {
  if (durationMs == null) return "";
  if (durationMs === 0) return "00:00:00";

  const seconds = Math.floor((durationMs / 1000) % 60);
  const minutes = Math.floor((durationMs / 1000 / 60) % 60);
  const hours = Math.floor(durationMs / 1000 / 3600);

  return [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ].join(":");
};

export const convertSecondsToTimeDisplay = (durationSeconds?: number) => {
  if (durationSeconds == null) return "";
  const durationMs = durationSeconds * 1000;
  return convertMsToTimeDisplay(durationMs);
};
