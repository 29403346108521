import React from "react";
import { Box, Card, CardContent, Tooltip, Typography, useTheme } from "@mui/material";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";


interface AnalyticWidgetProps {
  title: string;
  units?: string;
  total: number | string | undefined;
  color: "primary" | "secondary" | "error" | "warning" | "info" | "success";
  icon: React.ReactNode;
  tooltipContent: string;
  isLoading: boolean;
  linkTo?: string;
}

const AnalyticWidget = ({
  title,
  total,
  units,
  color,
  icon,
  tooltipContent,
  isLoading,
  linkTo,
}: AnalyticWidgetProps) => {
  const theme = useTheme();
  const cardContent = (
    <motion.div
      whileHover={{ scale: 1.02, boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)" }} // Subtle lift effect
      transition={{ type: "spring", stiffness: 150, damping: 15 }} // Softer bounce
    >
      <Card sx={{ height: 125 }}>
        <Tooltip
          title={tooltipContent}
          followCursor={false}
          arrow
          enterDelay={500}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 64,
                  height: 64,
                  borderRadius: 5,
                  backgroundColor: theme.palette.customColors?.wGreen,
                  marginRight: 2,
                }}
              >
                {icon}
              </Box>
              {isLoading ? (
                <LoadingIndicator />
              ) : (
                <Box>
                  <Typography variant="h6" color={"primary"}>
                    {title}
                  </Typography>
                  <Typography variant="h4" color={color}>
                    {total?.toLocaleString()} {units}
                  </Typography>
                </Box>
              )}
            </Box>
          </CardContent>
        </Tooltip>
      </Card>
    </motion.div>
  );

  // conditionally render link based on if linkTo is provided
  return (
    <>
      {linkTo ? (
        <Link to={linkTo} style={{ textDecoration: "none" }}>
          {cardContent}
        </Link>
      ) : (
        cardContent
      )}
    </>
  );
};

export default AnalyticWidget;
