import { Card, CardContent, Typography, useTheme } from "@mui/material";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import { InteractionsByYear } from "../../types/dashboard/Dashboard";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

interface InteractionAnalyticsBarChartProps {
  interactionsByYear: InteractionsByYear | undefined;
  isBarChartLoading: boolean;
}

const InteractionAnalyticsBarChart = ({
  interactionsByYear,
  isBarChartLoading,
}: InteractionAnalyticsBarChartProps) => {
  const [chartData, setChartData] = useState<{
    categories: string[];
    series: number[];
  }>({
    categories: [],
    series: [],
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const { customerId } = useParams();

  useEffect(() => {
    if (isBarChartLoading || !interactionsByYear) return;

    if (interactionsByYear) {
      const days = Object.keys(interactionsByYear);
      const count = Object.values(interactionsByYear);

      const dates = days.map((day) => dayjs.utc(day).format("YYYY"));

      setChartData({
        categories: dates,
        series: count,
      });
    }
  }, [isBarChartLoading, interactionsByYear]);

  // navigate to search page on bar click
  const handleBarClick = (_event: any, _chartContext: any, config: any) => {
    const clickedDateString = chartData.categories[config.dataPointIndex];

    const startDateObj = dayjs(clickedDateString, "YYYY").utc();
    const endDateObj = startDateObj.add(1, "year").utc();

    const params = new URLSearchParams({
      from: startDateObj.format("YYYY-MM-DDTHH:mm:ss"),
      to: endDateObj.format("YYYY-MM-DDTHH:mm:ss"),
    });

    navigate(`/customers/${customerId}/search?${params.toString()}`);
  };

  // to make cursor pointer on hover
  const handleMouseMove = (_event: any, chartContext: any, config: any) => {
    const chartElement = chartContext.el;
    if (config.dataPointIndex !== -1) {
      chartElement.style.cursor = "pointer";
    } else {
      chartElement.style.cursor = "default";
    }
  };

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: true,
        offsetY: -30,
      },
      type: "bar",
      height: 350,
      events: {
        dataPointSelection: handleBarClick,
        mouseMove: handleMouseMove,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      labels: {
        formatter: (value) => Math.floor(value).toString(), // Ensures whole numbers are displayed
      },
      forceNiceScale: true, // Helps in keeping the intervals clean
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    colors: [theme.palette.customColors?.wDarkBlue],
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      name: "Interactions",
      data: chartData.series,
    },
  ];

  return (
    <>
      {isBarChartLoading && <LoadingIndicator />}
      <Card>
        <CardContent>
          <Typography variant="h6">Interactions by Year</Typography>
          <Chart options={options} series={series} type="bar" height={385} />
        </CardContent>
      </Card>
    </>
  );
};

export default InteractionAnalyticsBarChart;
