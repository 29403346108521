import { AxiosError, HttpStatusCode } from "axios";
import { Transcription } from "../types/transcription/Transcription";
import { getApi } from "./helpers/apiMethods";
import { getInteractionMetadata } from "./interaction";

export async function initiateOrGetTranscription(
  customerId: string,
  interactionId: string
): Promise<InitiateOrGetTranscriptionResponse> {
  try {
    const response = await getApi<InitiateOrGetTranscriptionResponse>(
      `/v1/customers/${customerId}/interactions/${interactionId}/transcription`
    );
    if (!response.data) {
      throw new Error(
        "Unable to initiate or get transcription. Please check the customer ID and interaction ID."
      );
    }
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const response = error.response;
      if (response?.status === HttpStatusCode.Conflict) {
        throw new TranscriptionInProgressError();
      } else if (response?.status !== HttpStatusCode.Ok) {
        throw new Error(`Failed to initiate or get transcription.`);
      }
    }
    throw error;
  }
}

interface InitiateOrGetTranscriptionResponse {
  transcriptionJobName: string;
  transcribedUrl?: string;
}

export async function getTranscript(
  customerId: string,
  interactionId: string
): Promise<Transcription | undefined> {
  const getTranscriptionResponse = await initiateOrGetTranscription(
    customerId,
    interactionId
  );
  if (!getTranscriptionResponse.transcribedUrl) {
    throw new TranscriptionInProgressError();
  }
  const transcriptResponse = await fetch(
    getTranscriptionResponse.transcribedUrl
  );
  if (!transcriptResponse.ok) {
    throw new Error("The transcription exists but failed to load.");
  }
  return (await transcriptResponse.json()) as Transcription;
}

export enum TranscriptionStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  QUEUED = "QUEUED",
}

export async function getTranscriptionStatus(
  customerId: string,
  interactionId: string
): Promise<TranscriptionStatus | null> {
  const interaction = await getInteractionMetadata({
    customerId,
    interactionId,
  });
  console.log(interaction.transcriptionStatus);
  if (!interaction?.transcriptionStatus) {
    return null;
  }
  return interaction.transcriptionStatus as TranscriptionStatus;
}

export class TranscriptionInProgressError extends Error {
  constructor() {
    super("Transcription is in progress. Please wait a moment and try again.");
    this.name = "TranscriptionInProgressError";
  }
}
