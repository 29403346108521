import { PieChart as Chart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { Card, CardContent, Typography } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { CardinalDirections } from "@mui/x-charts";
import { AnchorPosition } from "@mui/x-charts/ChartsLegend/legend.types";

const WIDTH_THRESHOLD = 1600;

export type PieChartProps = {
  widget: any;
};

const PieChart = ({ widget }: PieChartProps) => {
  const theme = useTheme();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const COLORS = theme.palette.customColors
    ? Object.values(theme.palette.customColors)
    : [];
  const { labels, values } = widget.data;
  const totalValue = values.reduce(
    (sum: number, value: number) => sum + value,
    0
  )

  useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  const chartData = useMemo(() => {
    return labels.map((label: string, index: number) => ({
      id: index,
      value: values[index],
      label: label,
      percentage: (values[index] / totalValue) * 100,
    }));
  }, [labels, values, totalValue]);

   const pieMargins: CardinalDirections<number> = {
      bottom: windowWidth < WIDTH_THRESHOLD ? 100 : 50,
      right: windowWidth < WIDTH_THRESHOLD ? 50 : 200,
    };
  
    const legendPosition: AnchorPosition = {
      vertical: windowWidth < WIDTH_THRESHOLD ? "bottom" : "middle",
      horizontal: windowWidth < WIDTH_THRESHOLD ? "middle" : "right",
    };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h6">{widget.title}</Typography>
          <Chart
            height={300} 
            margin={pieMargins}
            series={[
              {
                data: chartData,
                highlightScope: { fade: "global", highlight: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },
                type: "pie",
                paddingAngle: 1,
                cornerRadius: 3,
                innerRadius: "60%",
                arcLabelMinAngle: 35,
                arcLabel: (item) =>
                  `${Math.round(
                    (item.value / totalValue) * 100
                  )}%`,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 14,
                fontWeight: "bold",
              },
            }}
            colors={COLORS}
            slotProps={{
              legend: {
                position: legendPosition,  
                direction:
                  window.innerWidth < WIDTH_THRESHOLD ? "row" : "column",
                 
              },
            }}
          />
      </CardContent>
    </Card>
  );
};

export default PieChart;
