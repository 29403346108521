import { FormHelperText, TextField, FormGroup} from "@mui/material";
import {
  GCPCredentialsRequest,
  gcpCredentialsSchema,
} from "../../../../api/externalStorage";
import StorageForm from "./StorageForm";
import { useUpdateStorageCredentials } from "../../../../hooks/useExternalStorage";

interface UpdateGCPStorageCredentialsProps {
  siteId: string;
  setIsOpen: (isOpen: boolean) => void;
}


export default function UpdateGCPStorageCredentials({
  siteId,
  setIsOpen,
}: UpdateGCPStorageCredentialsProps) {

  const mutationHook = useUpdateStorageCredentials;
  const GCP_CRED_DEFAULT_VALUES = {
    SiteId:siteId
  }

  const handleGcpFormData =  (data: GCPCredentialsRequest) => {
    const parsedAccessKey = JSON.parse(data.GCPServiceAccountCredentials.ServiceAccountKeyFile);
    data.GCPServiceAccountCredentials.ServiceAccountKeyFile = parsedAccessKey;
    return data; 
  };

  return (
  <StorageForm 
      id="edit-gcp-credentials"
      siteId={siteId}
      schema={gcpCredentialsSchema}
      options={{
       defaultValues: GCP_CRED_DEFAULT_VALUES
      }}
      mutationHook={mutationHook}
      requestProcessor={handleGcpFormData}
      setIsOpen={setIsOpen}
    >
      {(form) => {
        const {register, formState} = form;

        return (
          <>
          <FormGroup>
              <TextField
                label="Access Key (JSON)"
                required
                fullWidth
                multiline
                minRows={6}
                {...register(
                  "GCPServiceAccountCredentials.ServiceAccountKeyFile"
                )}
                error={
                  !!formState.errors.GCPServiceAccountCredentials
                    ?.ServiceAccountKeyFile
                }
              />
              <FormHelperText
                error={
                  !!formState.errors.GCPServiceAccountCredentials
                    ?.ServiceAccountKeyFile?.message
                }
              >
                {
                  formState.errors.GCPServiceAccountCredentials
                    ?.ServiceAccountKeyFile?.message
                }
              </FormHelperText>
            </FormGroup>
          </>
        )
      }}
  </StorageForm>
  );
}
