import Search from "../search/Search";
import { useParams } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { useView } from "../../hooks/useView";

export default function ViewsResults() {
  const { viewId, customerId } = useParams();
  const { data, isLoading } = useView(customerId, viewId);

  return (
    <>
      {!isLoading && (
        <Box sx={{ width: "100%", margin: 2 }}>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {data?.name}
          </Typography>

          <Typography variant="body2">{data?.description}</Typography>
          {data?.retentionDate && (
            <Typography variant="body2" color="textSecondary">
              {new Date(data.retentionDate * 1000).toLocaleString()}
            </Typography>
          )}
        </Box>
      )}
      <Search viewId={viewId} />
    </>
  );
}
