import {
  GetInteractionsRequest,
  GetInteractionsResponse,
} from "../api/interaction";
import { useQuery } from "@tanstack/react-query";
import { getInteractions } from "../api/interaction";
import { getViewInteractions } from "../api/view";

export const useSearch = (
  customerId?: string,
  viewId?: string,
  request?: GetInteractionsRequest
) => {
  return useQuery<GetInteractionsResponse, Error>({
    queryKey: ["interactions", "view-interactions", customerId, request],
    queryFn: () => {
      if (viewId) {
        return getViewInteractions(customerId!, viewId!, request!);
      } else {
        return getInteractions(customerId!, request!);
      }
    },
    enabled: !!customerId || !!request,
  });
};
