import { ExtractionDashboard } from "../types/extraction-dashboard/ExtractionDashboard"
import { getApi } from "./helpers/apiMethods";

export const getExtractionDashboard = async (
  customerId: string
): Promise<ExtractionDashboard | undefined> => {
  const response = await getApi<ExtractionDashboard>(
    `/v1/customers/${customerId}/extraction-dashboard`
  );
  return response.data
}