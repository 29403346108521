import ApiErrors, { handleApiError } from "./helpers/errorHandling";
import View from "../types/view/View";
import { deleteApi, getApi, postApi, putApi } from "./helpers/apiMethods";
import { ViewFormValues } from "../pages/views/ViewDialog";
import { AxiosError, HttpStatusCode } from "axios";
import ResourceInUseError from "../errors/ResourceInUseError";
import Interaction from "../types/interaction/Interaction";
import {
  cleanRequest,
  GetInteractionsRequest,
  GetInteractionsResponse,
} from "./interaction";

const path = "views";

export type GetViewsResponse = {
  views: View[];
  errors: ApiErrors;
};
export type GetViewInteractionsResponse = {
  interactions: Interaction[];
  nextPage: number | null;
  previousPage: number | null;
  totalPages: number;
  totalCount: number;
  errors: ApiErrors;
};

export type CreateViewResponse = {
  view?: View;
  errors: ApiErrors;
};

export type UpdateViewResponse = {
  view?: View;
  errors: ApiErrors;
};

export interface CreateViewRequest {
  customerId: string;
  roleId: string;
  name: string;
  retentionDate: number | undefined;
  description: string;
}

export interface UpdateViewRequest extends ViewFormValues {
  customerId: string;
  viewId: string;
}

export interface DeleteViewRequest {
  customerId: string;
  viewId: string;
}

export const getView = async (
  customerId: string,
  viewId: string
): Promise<View | undefined> => {
  type Response = {view: View}
  const response = await getApi<Response>(
    `/v1/customers/${customerId}/${path}/${viewId}/`
  );
  return response?.data?.view;
};

export const getViews = async (
  customerId: string
): Promise<View[]> => {
    type Response = { views: View[] };
    const response = await getApi<Response>(
      `/v1/customers/${customerId}/${path}/`
    );
    const views: View[] = response.data?.views ?? [];
    return views
};
export const getViewInteractions = async (
  customerId: string,
  viewId: string,
  request: GetInteractionsRequest
): Promise<GetInteractionsResponse> => {
  const response = await getApi<GetInteractionsResponse>(
    `/v1/customers/${customerId}/${path}/${viewId}/interactions`,
    {
      params: cleanRequest(request),
    }
  );
  return (
    response.data ?? {
      interactions: [],
      nextPage: 1,
      previousPage: null,
      totalPages: 0,
      totalCount: 0,
    }
  );
};

export const createView = async ({
  customerId,
  ...request
}: CreateViewRequest): Promise<CreateViewResponse> => {
  try {
    const response = await postApi<CreateViewResponse>(
      `/v1/customers/${customerId}/${path}/`,
      request
    );
    const view = response.data?.view;
    return { view, errors: new ApiErrors() };
  } catch (error: unknown) {
    const errorMessage = handleApiError(error);
    return { errors: errorMessage };
  }
};

export const updateView = async ({
  customerId,
  viewId,
  ...request
}: UpdateViewRequest): Promise<UpdateViewResponse> => {
  try {
    const response = await putApi<UpdateViewResponse>(
      `/v1/customers/${customerId}/${path}/${viewId}/`,
      request
    );
    const view = response.data?.view;
    return { view, errors: new ApiErrors() };
  } catch (error: unknown) {
    const errorMessage = handleApiError(error);
    return { errors: errorMessage };
  }
};

export const deleteView = async ({
  customerId,
  viewId,
  ...request
}: DeleteViewRequest): Promise<View | undefined> => {
  try {
    type Response = { view: View };
    const response = await deleteApi<Response>(
      `/v1/customers/${customerId}/${path}/${viewId}/`
    );
    return response.data?.view;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const statusCode =
        error.status ??
        error.response?.status ??
        HttpStatusCode.InternalServerError;
      if (statusCode === HttpStatusCode.Conflict) {
        throw new ResourceInUseError(
          "This view is in use and cannot be deleted. Remove this view from all interactions and try again."
        );
      }
    } 
    throw error;
  }
};
