import { Box, Divider, Typography } from "@mui/material";
import { TranscriptionBlock, TranscriptionReader } from "../../types/transcription/Transcription";
import { useEffect, useState } from "react";
import { useTranscript, useTranscriptionStatus } from "../../hooks/useTranscription";
import LoadingIndicator from "../common/LoadingIndicator/LoadingIndicator";
import { TranscriptionStatus } from "../../api/transcription";
import { convertSecondsToTimeDisplay } from "../../helpers/displayHelpers";
import { TranscribeButton } from "./TranscribeButton";

export interface TranscriptionViewProps {
  customerId: string;
  interactionId: string;
  className?: string;
}

export function TranscriptionView({ customerId, interactionId, className = '' }: Readonly<TranscriptionViewProps>) {
  const { data: status, error, isLoading } = useTranscriptionStatus(customerId, interactionId);

  if (error) {
    return (
      <Box>
        <Typography variant="body1" color="error">
          Failed to load transcription status
        </Typography>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box className={`${className} flex justify-center p-4`}>
        <LoadingIndicator />
      </Box>
    );
  }

  if (status === TranscriptionStatus.COMPLETED) {
    return <CompletedTranscription customerId={customerId} interactionId={interactionId} className={className} />;
  } else if (status && [TranscriptionStatus.IN_PROGRESS, TranscriptionStatus.QUEUED].includes(status)) {
    return <TranscriptionInProgress />;
  } else if (status === TranscriptionStatus.FAILED) {
    return <TranscriptionFailed />;
  }

  return (
    <Box className={`${className} flex flex-col justify-center p-4`}>
      <Box className='mt-2'>
        <TranscribeButton customerId={customerId} interactionId={interactionId} />
      </Box>
    </Box>
  );
}

function CompletedTranscription({ customerId, interactionId, className }: Readonly<TranscriptionViewProps>) {
  const { data: transcription, error, isLoading } = useTranscript(customerId, interactionId);
  const [reader, setReader] = useState<TranscriptionReader | undefined>();

  useEffect(() => {
    if (!transcription) {
      return;
    }
    setReader(new TranscriptionReader(transcription));
  }, [transcription]);

  if (error) {
    return (
      <Box className={`${className} flex flex-col p-4`}>
        <Typography variant="body1" color="error">
          {error.message}
        </Typography>
      </Box>
    )
  }

  return (
    <Box className={`${className} flex flex-col overflow-y-scroll pr-8`}>
      {isLoading && (
        <LoadingIndicator />
      )}
      {reader?.blocks.map((block) => (
        <TranscriptionBlockView key={block.id} className="pb-4" transcriptionBlock={block} />
      ))}
    </Box>
  );
}

interface TranscriptionBlockViewProps {
  className?: string;
  transcriptionBlock: TranscriptionBlock;
}

function TranscriptionBlockView({ className, transcriptionBlock }: Readonly<TranscriptionBlockViewProps>) {
  return (
    <Box className={`${className} flex space-x-4 mb-4`}>
      <Box className="">
        <Typography variant='subtitle2' color='gray'>{convertSecondsToTimeDisplay(transcriptionBlock.startSeconds)}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Typography variant="body1">{transcriptionBlock.text}</Typography>
      </Box>
    </Box>
  );
}

function TranscriptionInProgress() {
  return (
    <Box className="flex justify-center p-4">
      <Typography variant="body1" color="text.secondary">
        Transcription is still in progress. Please check back later.
      </Typography>
    </Box>
  );
}

function TranscriptionFailed() {
  return (
    <Box className="flex justify-center p-4">
      <Typography variant="body1" color="error">
        An error occurred while transcribing this interaction.
      </Typography>
    </Box>
  );
}