import { getApi, postApi } from "./helpers/apiMethods";

export interface GetBulkExportRequest {
  customerId: string | undefined;
  jobId: string;
}

export interface GetBulkExportResponse {
  downloadUrl: string;
}

export interface CreateBulkExportRequest {
  customerId: string | undefined;
  interactionIds: string[];
}

export interface CreateBulkExportResponse {
  jobId?: string;
}

/**
 * Gets a URL that points to the location of the bulk export
 * @param params a customerId and jobId to search by
 * @returns a string url
 */
export const getBulkExportResult = async (
  params: GetBulkExportRequest
): Promise<string> => {
  const response = await getApi<GetBulkExportResponse>(
    `/v1/customers/${params.customerId}/bulk-export/download/${params.jobId}`
  );

  return response.data?.downloadUrl ?? "";
};

/**
 * Starts a bulk export job request
 * @param interactionIds a string[]
 * @returns a jobId string or undefined
 */
export const createBulkExport = async ({
  customerId,
  ...request
}: CreateBulkExportRequest): Promise<string> => {
  const response = await postApi<CreateBulkExportResponse>(
    `/v1/customers/${customerId}/bulk-export`,
    request
  );

  const newJob = response.data?.jobId;

  if (!newJob) {
    throw Error("Something went wrong when creating the bulk export.");
  }

  return newJob;
};
