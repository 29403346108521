import AWSRegionSelect from "../../common/AWSRegionSelect/AWSRegionSelect";
import AmazonConnectConnector, {
  AmazonConnectFormValues,
} from "../models/AmazonConnector";
import { ConnectorConfigFormProps } from "../models/Connector";
import {
  TextField,
  FormGroup,
  FormHelperText,
  Box,
  Typography,
  Divider,
} from "@mui/material";

export default function AmazonConnectConfigForm({
  defaultValues,
  register,
  errors,
}: ConnectorConfigFormProps<AmazonConnectFormValues, "AmazonConnect">) {
  const registerPrefix = AmazonConnectConnector.Type;

  return (
    <>
      <Typography variant="subtitle2" color="primary">
        Resource Configuration
      </Typography>
      <Divider />

      <FormGroup>
        <Box mb={2}>
          <AWSRegionSelect
            label="Source Bucket Region"
            {...register(`${registerPrefix}.SourceBucketRegion`, {
              required: true,
            })}
            defaultValue={defaultValues.SourceBucketRegion}
            error={!!errors?.AmazonConnect?.SourceBucketRegion}
            required
          />
          {errors?.AmazonConnect?.SourceBucketRegion && (
            <FormHelperText error>
              {errors.AmazonConnect.SourceBucketRegion.message}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2}>
          <TextField
            {...register(`${registerPrefix}.AmazonConnectARN`)}
            label="Amazon Connect ARN"
            defaultValue={defaultValues.AmazonConnectARN}
            error={!!errors?.AmazonConnect?.AmazonConnectARN}
            autoComplete="off"
            required
            fullWidth
          />
          {errors?.AmazonConnect?.AmazonConnectARN && (
            <FormHelperText error>
              {errors.AmazonConnect.AmazonConnectARN.message}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2}>
          <TextField
            {...register(`${registerPrefix}.RecordingBucketName`)}
            label="Recording Bucket Name"
            defaultValue={defaultValues.RecordingBucketName}
            error={!!errors?.AmazonConnect?.RecordingBucketName}
            autoComplete="off"
            required
            fullWidth
          />
          {errors?.AmazonConnect?.RecordingBucketName && (
            <FormHelperText error>
              {errors.AmazonConnect.RecordingBucketName.message}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2}>
          <TextField
            {...register(`${registerPrefix}.CTRBucketName`)}
            label="CTR Bucket Name"
            defaultValue={defaultValues.CTRBucketName}
            error={!!errors?.AmazonConnect?.CTRBucketName}
            autoComplete="off"
            required
            fullWidth
          />
          {errors?.AmazonConnect?.CTRBucketName && (
            <FormHelperText error>
              {errors.AmazonConnect.CTRBucketName.message}
            </FormHelperText>
          )}
        </Box>
      </FormGroup>

      <Typography variant="subtitle2" color="primary">
        Prefix Configuration
      </Typography>
      <Divider />

      <FormGroup>
        <Box mb={2}>
          <TextField
            {...register(`${registerPrefix}.CTRBucketPrefix`)}
            label="CTR Bucket Prefix"
            defaultValue={defaultValues.CTRBucketPrefix}
            error={!!errors?.AmazonConnect?.CTRBucketPrefix}
            autoComplete="off"
            required
            fullWidth
          />
          {errors?.AmazonConnect?.CTRBucketPrefix && (
            <FormHelperText error>
              {errors.AmazonConnect.CTRBucketPrefix.message}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2}>
          <TextField
            {...register(`${registerPrefix}.CallRecordingPrefix`)}
            label="Call Recording Prefix"
            defaultValue={defaultValues.CallRecordingPrefix}
            error={!!errors?.AmazonConnect?.CallRecordingPrefix}
            autoComplete="off"
            fullWidth
          />
          {errors?.AmazonConnect?.CallRecordingPrefix && (
            <FormHelperText error>
              {errors.AmazonConnect.CallRecordingPrefix.message}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2}>
          <TextField
            {...register(`${registerPrefix}.ChatTranscriptPrefix`)}
            label="Chat Transcript Prefix"
            defaultValue={defaultValues.ChatTranscriptPrefix}
            error={!!errors?.AmazonConnect?.ChatTranscriptPrefix}
            autoComplete="off"
            fullWidth
          />
          {errors?.AmazonConnect?.ChatTranscriptPrefix && (
            <FormHelperText error>
              {errors.AmazonConnect.ChatTranscriptPrefix.message}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2}>
          <TextField
            {...register(`${registerPrefix}.ScreenRecordingPrefix`)}
            label="Screen Recording Prefix"
            defaultValue={defaultValues.ScreenRecordingPrefix}
            error={!!errors?.AmazonConnect?.ScreenRecordingPrefix}
            autoComplete="off"
            fullWidth
          />
          {errors?.AmazonConnect?.ScreenRecordingPrefix && (
            <FormHelperText error>
              {errors.AmazonConnect.ScreenRecordingPrefix.message}
            </FormHelperText>
          )}
        </Box>
      </FormGroup>

      <Typography variant="subtitle2" color="primary">
        Access Configuration
      </Typography>
      <Divider />

      <FormGroup>
        <Box mb={2}>
          <TextField
            {...register(`${registerPrefix}.AccessRoleARN`)}
            label="AWS Role ARN"
            defaultValue={defaultValues.AccessRoleARN}
            error={!!errors?.AmazonConnect?.AccessRoleARN}
            autoComplete="off"
            required
            fullWidth
          />
          {errors?.AmazonConnect?.AccessRoleARN && (
            <FormHelperText error>
              {errors.AmazonConnect.AccessRoleARN.message}
            </FormHelperText>
          )}
        </Box>
      </FormGroup>
    </>
  );
}
