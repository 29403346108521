import {
  FieldValues,
  UseFormProps,
  UseFormReturn as RhfUseFormReturn,
  useForm as useRhfForm,
  Path,
  RegisterOptions,
  UseFormRegisterReturn,
  FieldPath,
} from "react-hook-form";

export interface UseFormReturn<
  TFieldValues extends FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined
> extends RhfUseFormReturn<TFieldValues, TContext, TTransformedValues> {
  registerWithoutRef: <
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >(
    name: TFieldName,
    options?: RegisterOptions<TFieldValues, TFieldName>
  ) => Omit<UseFormRegisterReturn<TFieldName>, "ref">;
}

export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined
>(
  props?: UseFormProps<TFieldValues, TContext>
): UseFormReturn<TFieldValues, TContext, TTransformedValues> {
  const form = useRhfForm(props);

  function registerWithoutRef<TFieldName extends Path<TFieldValues>>(
    name: TFieldName,
    options?: RegisterOptions<TFieldValues, TFieldName>
  ) {
    return form.register(name, options);
  }

  return { ...form, registerWithoutRef };
}
