import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import {
  createConfigureStorage,
  CreateStorageConfigurationResponse,
  updateStorageCredentials,
  UpdateStorageCredentialsResponse,
} from "../api/externalStorage";

export const useCreateExternalStorage = <
  TRequest extends { customerId: string; siteId: string }
>() => {
  const mutationOptions: UseMutationOptions<
    CreateStorageConfigurationResponse,
    Error,
    TRequest
  > = {
    mutationFn: (request: TRequest) => {
      return createConfigureStorage(request);
    },
  };
  return useMutation(mutationOptions);
};

export const useUpdateStorageCredentials = <
  TRequest extends { customerId: string; siteId: string }
>() => {
  const mutationOptions: UseMutationOptions<
    UpdateStorageCredentialsResponse,
    Error,
    TRequest,
    unknown
  > = {
    mutationFn: (request: TRequest) => {
      return updateStorageCredentials(request);
    },
  };
  return useMutation(mutationOptions);
};
