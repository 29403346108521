import { Card, CardContent, Typography } from "@mui/material";
import { LineChart as Chart } from "@mui/x-charts/LineChart";
import { useTheme } from "@mui/material/styles";
export type LineChartProps = {
  widget: any;
};

const LineChart = ({ widget }: LineChartProps) => {
  const { labels, values } = widget.data;
  const theme = useTheme();
  const seriesData = [
    {
      data: values,
      area:true,
      color:theme.palette.primary.main
    },
  ];

  const xLabels = labels;

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent >
        <Typography variant="h6">{widget.title}</Typography>
        <Chart
          height={300}
          series={seriesData}
          xAxis={[{ scaleType: 'point', data: xLabels }]}
        />
      </CardContent>
    </Card>
  );
};

export default LineChart;
