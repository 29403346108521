import { Box, LinearProgress, Chip } from "@mui/material";
import { useView } from "../../../hooks/useView";

export interface ViewNameDisplayProps {
  customerId?: string;
  viewId?: string;
}

export default function ViewNameDisplay(params: ViewNameDisplayProps) {
  const { data, isLoading } = useView(params.customerId, params.viewId);
  return isLoading ? (
    <Box>
      <LinearProgress />
    </Box>
  ) : (
    <Chip label={data?.name} variant="outlined" size="small" />
  );
}
