import { KpiWidgetData } from "../types/extraction-dashboard/Widget";

export default function dashboardThresholdHelper(widgetData: KpiWidgetData) {
  let valueColor = "gray";

  const numericValue =
    typeof widgetData.value === "number"
      ? widgetData.value
      : !isNaN(parseFloat(widgetData.value.replace(/[^0-9.-]+/g, "")))
      ? parseFloat(widgetData.value.replace(/[^0-9.-]+/g, ""))
      : NaN;

  if (!widgetData.thresholds || isNaN(numericValue)) {
    valueColor = "gray";
  } else {
    const { good, warning, critical } = widgetData.thresholds;

    if (
      good &&
      numericValue >= good.min &&
      (numericValue <= good.max || good.max == null)
    ) {
      valueColor = good.color;
    } else if (
      warning &&
      numericValue <= warning.max &&
      numericValue >= warning.min
    ) {
      valueColor = warning.color;
    } else if (
      critical &&
      numericValue >= critical.min &&
      numericValue <= critical.max
    ) {
      valueColor = critical.color;
    } else {
      valueColor = "gray";
    }
  }

  return valueColor;
}
