import FieldLabelProvider, {
  FieldLabelProviderProps,
} from "./FieldLabelProvider";

export interface SearchFieldLabelProviderProps
  extends Omit<FieldLabelProviderProps, "labelMapping"> {}

const SearchFieldLabelProvider = ({
  children,
  tableId,
}: SearchFieldLabelProviderProps) => {
  const labelMapping: Record<string, string> = {
    siteId: "Site",
    agentId: "Agent ID",
    agentName: "Agent Name",
    startTime: "Start Time",
    endTime: "End Time",
    sourceId: "Source ID",
    direction: "Direction",
    device: "Device",
    phoneNumber: "Phone Number",
    dnis: "DNIS",
    extension: "Extension",
    durationMs: "Duration",
    group: "Group",
    skill: "Skill",
    interactionId: "Interaction ID",
    masterInteractionId: "Master Contact ID",
    retentionDate: "Retention Date",
    legalHold: "Legal Hold",
    hasMedia: "Has Media",
    segmentId: "Segment ID",
    participants: "Participants",
    segmentIds: "Segment IDs",
    tags: "Tags",
    interactionType: "Interaction Type",
  };

  return (
    <FieldLabelProvider tableId={tableId} labelMapping={labelMapping}>
      {children}
    </FieldLabelProvider>
  );
};

export default SearchFieldLabelProvider;
