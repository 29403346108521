import {
  Query,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getTranscriptionStatus,
  getTranscript,
  initiateOrGetTranscription,
  TranscriptionStatus,
} from "../api/transcription";

export function useTranscribe(customerId?: string, interactionId?: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["transcribe", customerId, interactionId],
    mutationFn: () => initiateOrGetTranscription(customerId!, interactionId!),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["transcriptionStatus", customerId, interactionId],
      });
    },
    retry: false,
  });
}

export function useTranscript(
  customerId?: string,
  interactionId?: string,
  enabled: boolean = true
) {
  return useQuery({
    queryKey: ["transcription", customerId, interactionId],
    queryFn: () => getTranscript(customerId!, interactionId!),
    enabled: !!customerId && !!interactionId && enabled,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });
}

export function useTranscriptionStatus(
  customerId?: string,
  interactionId?: string
) {
  return useQuery({
    queryKey: ["transcriptionStatus", customerId, interactionId],
    queryFn: () => getTranscriptionStatus(customerId!, interactionId!),
    refetchInterval: refetchEveryFiveSecondsUnlessCompleted,
    enabled: !!customerId && !!interactionId,
  });
}

function refetchEveryFiveSecondsUnlessCompleted(
  query: Query<
    TranscriptionStatus | null,
    Error,
    TranscriptionStatus | null,
    (string | undefined)[]
  >
): number | undefined {
  if (query.state.data !== undefined && query.state.data !== "COMPLETED") {
    return 5_000;
  }
}
