import {
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { StorageType } from "../../types/external-storage/StorageType";
import { useState } from "react";
import ConfigureGCPStorageForm from "./form/create-storage/ConfigureGCPStorageForm";
import AWSAuthenticationSelector from "./form/create-storage/AWSAuthenticationSelector";

interface StorageTypeSelectorProps {
  siteId: string;
  id: string;
  setIsOpen: (isOpen: boolean) => void;
}

export default function StorageTypeSelector({
  siteId,
  id,
  setIsOpen,
}: StorageTypeSelectorProps) {
  const [selectedStorageType, setSelectedStorageType] = useState<StorageType>(
    StorageType.GCPCloud
  );

  const handleOnChange = (event: SelectChangeEvent<string>) => {
    setSelectedStorageType(event.target.value as StorageType);
  };

  return (
    <div>
      <div className="my-6">
        <FormGroup>
          <FormControl>
            <InputLabel id="storage-platform-select">
              Storage Platform
            </InputLabel>
            <Select
              label="Storage Platform"
              labelId="storage-platform-select"
              value={selectedStorageType}
              onChange={handleOnChange}
              required
            >
              <MenuItem value={StorageType.GCPCloud}>GCP Bucket</MenuItem>
              <MenuItem value={StorageType.ExternalAmazonS3}>
                AWS Bucket
              </MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
      </div>

      {selectedStorageType === StorageType.GCPCloud && (
        <ConfigureGCPStorageForm
          id={id}
          storageType={selectedStorageType}
          siteId={siteId}
          setIsOpen={setIsOpen}
        />
      )}
      {selectedStorageType === StorageType.ExternalAmazonS3 && (
        <AWSAuthenticationSelector
          id={id}
          siteId={siteId}
          storageType={selectedStorageType}
          setIsOpen={setIsOpen}
        />
      )}
    </div>
  );
}
