import CustomTabPanel from "../../components/common/CustomPanel/CustomTabPanel";
import { FormGroup, TextField, Button } from "@mui/material";
import { z } from "zod";
import { EditSiteFormValues, CreateSiteFormValues } from "./SiteDialog";
import { SubmitHandler } from "react-hook-form";
import Form from "../../components/common/Form/Form";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";

const schema = z.object({
  name: z.string().min(1, { message: "Name is required." }),
});
const DEFAULT_CREATE_VALUES: CreateSiteFormValues = {
  name: "",
};

interface SitePanelProps {
  id: string;
  isOpen: boolean;
  action: string;
  setIsOpen: (isOpen: boolean) => void;
  createSite?: (data: CreateSiteFormValues) => void;
  editSite?: (data: EditSiteFormValues) => void;
  formValues?: EditSiteFormValues | CreateSiteFormValues;
  selectedTab: number;
}
export default function SitePanel({
  id,
  isOpen,
  action,
  createSite,
  editSite,
  formValues,
  selectedTab,
}: SitePanelProps) {
  const onSubmit: SubmitHandler<EditSiteFormValues | CreateSiteFormValues> = (
    data
  ) => {
    if (action === "Create" && createSite) {
      createSite(data);
    } else if (action === "Edit" && editSite) {
      editSite(data as EditSiteFormValues);
    }
  };
  return (
    <CustomTabPanel index={0} value={selectedTab}>
      <Form
        id={id}
        onSubmit={onSubmit}
        schema={schema}
        options={{ defaultValues: formValues || DEFAULT_CREATE_VALUES }}
      >
        {({ register, formState }) => {
          const { isSubmitting, isDirty, errors } = formState;
          return (
            <>
              <FormGroup>
                <TextField
                  label="Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                  {...register("name", {
                    required: "Name is required",
                  })}
                />
              </FormGroup>
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={isSubmitting || formState.isLoading || !isDirty}
                >
                  {isSubmitting ? <LoadingIndicator /> : "Save"}
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </CustomTabPanel>
  );
}
