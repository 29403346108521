import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import {
  GridSortModel,
  useGridApiRef,
  type DataGridProps,
  type GridInitialState,
  type GridRenderCellParams,
} from "@mui/x-data-grid";
import { useContext, useState } from "react";
import AuthorizedIconButton from "../../components/common/AuthorizedIconButton/AuthorizedIconButton";
import DataTable, {
  DataTableColDef,
} from "../../components/common/DataTable/DataTable";
import { SiteNameDisplay } from "../../components/common/FriendlyDisplay/SiteNameDisplay";
import { convertMsToTimeDisplay } from "../../helpers/displayHelpers";
import type Interaction from "../../types/interaction/Interaction";
import DownloadInteractionFileButton from "./DownloadInteractionFileButton";
import InteractionInfo from "./InteractionInfo";
import FieldLabelContext from "./context/FieldLabelContext";
import SegmentButton from "./components/SegmentButton";
import { getRetentionDate } from "../../types/interaction/Interaction";
import MediaTypeIndicator from "./components/MediaTypeIndicator";
import { InteractionTableToolbarProps } from "../../pages/search/components/table/InteractionTableToolbar";
import InteractionTableToolbar from "./components/table/InteractionTableToolbar";
import { AuthContext } from "../../context/AuthContext";
import { RowSelectorMode } from "../../types/datatable/RowSelectorMode";
import { sortStringsNaturally } from "../../helpers/utils/sortHelpers";
import EditInteractionModal from "./components/EditInteractionModal";
import ViewNameDisplay from "../../components/common/FriendlyDisplay/ViewNameDisplay";

declare module "@mui/x-data-grid" {
  interface ToolbarPropsOverrides extends InteractionTableToolbarProps {}
}

export const SEARCH_DEFAULT_SORT_MODEL: GridSortModel = [
  {
    field: "startTime",
    sort: "desc",
  },
];

interface SearchResultsProps
  extends Omit<
    DataGridProps,
    "columns" | "rows" | "getRowId" | "initialStateValue"
  > {
  tableId: string;
  results: Interaction[];
  customerId: string;
  isLoading?: boolean;
  playerInteractionId: string;
  refresh: () => void | Promise<void>;
  setSelectedRows: React.Dispatch<React.SetStateAction<Interaction[]>>;
  selectedRows: Interaction[];
  setPlayerInteractionId: (interactionId: string) => void;
  onSortModelChange?: (sortModel: GridSortModel) => Promise<void> | void;
  setMediaDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pageIndex?: number;
}

const SearchResults = ({
  tableId,
  results,
  customerId,
  isLoading = false,
  playerInteractionId,
  refresh,
  setSelectedRows,
  selectedRows,
  setPlayerInteractionId,
  onSortModelChange,
  onPaginationModelChange,
  setMediaDrawerOpen,
  pageIndex,
  ...props
}: SearchResultsProps) => {
  const { user } = useContext(AuthContext);
  const [isInteractionInfoOpen, setIsInteractionInfoOpen] =
    useState<boolean>(false);
  const [infoInteractionId, setInfoInteractionId] = useState<string>("");
  // for edit to highlight the current row it is affecting
  const [highlightRow, setHighlightRow] = useState<string>("");
  const { getFieldLabel } = useContext(FieldLabelContext);
  const apiRef = useGridApiRef();
  const isInteractionEditable =
    user?.hasPermission("interactions:Update") ||
    user?.hasPermission("interactions:*");

  const columns: DataTableColDef[] = [
    {
      showMenuOnHover: false,
      renderCell: ({ row }: GridRenderCellParams<Interaction>) =>
        !row.fileName ? (
          <>
            <Tooltip placement="top" title="No recording">
              <IconButton>
                <DoNotDisturbIcon />
              </IconButton>
            </Tooltip>
            <MediaTypeIndicator interactionType={row.interactionType} />
          </>
        ) : (
          <>
            <AuthorizedIconButton
              permission="interactions:Replay"
              unauthorizedAction="disable"
              onClick={() => playOrStopInteraction(row)}
              aria-label="play-interaction-button"
            >
              <PlayCircleIcon
                className={
                  row.interactionId === playerInteractionId
                    ? "text-blue-500"
                    : ""
                }
              />
            </AuthorizedIconButton>
            <MediaTypeIndicator interactionType={row.interactionType} />
            <SegmentButton interaction={row} />
          </>
        ),
      field: "Play",
      headerName: getFieldLabel("Play"),
      sortable: false,
      hideable: false,
      filterable: false,
      minWidth: 150,
    },
    {
      field: "siteId",
      headerName: getFieldLabel("siteId"),
      type: "string",
      renderCell: (params) => (
        <SiteNameDisplay customerId={customerId} siteId={params.value} />
      ),
    },
    {
      field: "agentId",
      headerName: getFieldLabel("agentId"),
      type: "string",
    },
    {
      field: "agentName",
      headerName: getFieldLabel("agentName"),
      type: "string",
      hideable: false,
    },
    {
      field: "participants",
      headerName: getFieldLabel("participants"),
      type: "string",
      valueGetter: (_, row: Interaction) =>
        row.participants?.sort(sortStringsNaturally).join(", ") ?? "",
    },
    {
      field: "segmentIds",
      headerName: getFieldLabel("segmentIds"),
      type: "string",
      valueGetter: (_, row: Interaction) =>
        row.segmentIds?.sort().join(", ") ?? "",
    },
    {
      field: "startTime",
      headerName: getFieldLabel("startTime"),
      type: "string",
      valueFormatter: (value: string) => new Date(value).toLocaleString(),
      hideable: false,
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "endTime",
      headerName: getFieldLabel("endTime"),
      type: "string",
      valueFormatter: (value: string) => new Date(value).toLocaleString(),
      hideable: false,
    },
    {
      field: "durationMs",
      headerName: getFieldLabel("durationMs"),
      type: "number",
      valueFormatter: (value: number | undefined) =>
        convertMsToTimeDisplay(value),
    },
    {
      field: "direction",
      headerName: getFieldLabel("direction"),
      type: "string",
    },
    {
      field: "device",
      headerName: getFieldLabel("device"),
      type: "string",
    },
    {
      field: "extension",
      headerName: getFieldLabel("extension"),
      type: "string",
    },
    {
      field: "phoneNumber",
      headerName: getFieldLabel("phoneNumber"),
      type: "string",
    },
    {
      field: "dnis",
      headerName: getFieldLabel("dnis"),
      type: "string",
    },
    {
      field: "group",
      headerName: getFieldLabel("group"),
      type: "string",
    },
    {
      field: "skill",
      headerName: getFieldLabel("skill"),
      type: "string",
    },
    {
      field: "sourceId",
      headerName: getFieldLabel("sourceId"),
      type: "string",
    },
    {
      field: "masterInteractionId",
      headerName: getFieldLabel("masterInteractionId"),
      type: "string",
    },
    {
      field: "interactionId",
      headerName: getFieldLabel("interactionId"),
      type: "string",
    },
    {
      field: "retentionDate",
      headerName: getFieldLabel("retentionDate"),
      type: "dateTime",
      valueGetter: (_, row: Interaction): number | undefined => {
        return getRetentionDate(row) ?? undefined;
      },
      valueFormatter: (value: number | undefined) =>
        value ? new Date(value * 1000).toLocaleString() : "",
    },
    {
      field: "legalHold",
      headerName: getFieldLabel("legalHold"),
      type: "boolean",
      renderCell: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      field: "tags",
      headerName: getFieldLabel("tags"),
      type: "string",
      renderCell: ({ value }) =>
        value.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              gap: 0.5,
              padding: 0.5,
              margin: 0,
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            {value.map((tag: string, index: number) => (
              <Chip key={index} label={tag} size="small" />
            ))}
          </Box>
        ) : null,
    },
    {
      field: "views",
      headerName: "Views",
      type: "string",
      minWidth: 200,
      renderCell: ({ value }) => (
        <div style={{ display: "flex" }}>
          {value?.length > 0
            ? value.map((view: string, index: number) => (
                <ViewNameDisplay
                  key={index}
                  customerId={customerId}
                  viewId={view}
                />
              ))
            : null}
        </div>
      ),
    },
    {
      showMenuOnHover: false,
      renderCell: ({ row }: GridRenderCellParams<Interaction>) => {
        const canExport =
          user?.hasPermission("interactions:Export") ||
          user?.hasPermission("interactions:*");
        const isDisabled = !row.fileName || !canExport;
        const tooltipMessage = isDisabled
          ? "Download disabled. Ensure file exists and you have the correct permissions."
          : "Download interaction file";
        return (
          <>
            <Tooltip title={tooltipMessage}>
              <span>
                <DownloadInteractionFileButton
                  customerId={customerId}
                  interactionId={row.interactionId}
                  disabled={isDisabled}
                />
              </span>
            </Tooltip>
            {isInteractionEditable && (
              <EditInteractionModal
                row={row}
                customerId={customerId}
                setHighlight={setHighlightRow}
                apiRef={apiRef}
              />
            )}
            <Tooltip title="Info">
              <IconButton
                onClick={() => {
                  setIsInteractionInfoOpen(true);
                  setInfoInteractionId(row.interactionId);
                }}
                aria-label="display-interaction-info-button"
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      field: "Actions",
      headerName: getFieldLabel("Actions"),
      align: "left",
      minWidth: 145,
      sortable: false,
      hideable: false,
      filterable: false,
    },
  ];

  const getRowId = (row: Interaction) => row.interactionId;

  const playOrStopInteraction = (row: Interaction) => {
    if (playerInteractionId === row.interactionId) {
      setPlayerInteractionId("");
      setMediaDrawerOpen(false);
      return;
    }
    setPlayerInteractionId(row.interactionId);
    setMediaDrawerOpen(true);
  };

  const initialState: GridInitialState = {
    ...props.initialState,
    columns: {
      columnVisibilityModel: {
        from: false,
        to: false,
        callId: false,
        continuityId: false,
        agentId: false,
      },
      ...props.initialState?.columns,
    },
    sorting: {
      sortModel: SEARCH_DEFAULT_SORT_MODEL,
      ...props.initialState?.sorting,
    },
  };

  const handleSortModelChange = (model: GridSortModel) => {
    const newModel = model.length === 0 ? SEARCH_DEFAULT_SORT_MODEL : model;
    onSortModelChange?.(newModel);
    return newModel;
  };

  return (
    <>
      <DataTable
        {...props}
        refresh={refresh}
        apiRef={apiRef}
        rowCount={props.rowCount ?? 0}
        tableId={tableId}
        slots={{
          toolbar: InteractionTableToolbar,
        }}
        slotProps={{
          toolbar: {
            refresh,
          },
        }}
        columns={columns}
        rows={results}
        loading={isLoading}
        getRowId={getRowId}
        highlightRowId={highlightRow}
        initialState={initialState}
        editMode="row"
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        disableColumnFilter
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={handleSortModelChange}
        rowSelectorMode={RowSelectorMode.MULTIPLE}
        rowSelection
        rowSelectionModel={selectedRows.map((row) => row.interactionId)}
        onRowSelectionModelChange={(model) => {
          const selectedInteractions = results.filter(
            (row) => model.includes(row.interactionId) // Ensure the correct field is used
          );
          setSelectedRows(selectedInteractions);
        }}
        pageIndex={pageIndex}
      />
      <InteractionInfo
        isOpen={isInteractionInfoOpen}
        setIsOpen={setIsInteractionInfoOpen}
        interactionId={infoInteractionId}
        customerId={customerId}
      />
    </>
  );
};

export default SearchResults;
