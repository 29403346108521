import { type GridInitialState } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ResultsPage from "../../components/common/ResultsPage/ResultsPage";
import { useAlert } from "../../context/AlertContext";
import ErrorMessage from "../../types/feedback/ErrorMessage";
import View from "../../types/view/View";
import { useViews, useCreateView, useUpdateView } from "../../hooks/useView";
import ViewDialog, { ViewFormValues } from "./ViewDialog";
import DeleteViewDialog from "./DeleteViewDialog";
import { useGetRoles } from "../../hooks/useRole";
import { Tooltip, IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DataTableColDef } from "../../components/common/DataTable/DataTable";
import { RoleNameDisplay } from "../../components/common/FriendlyDisplay/RoleNameDisplay";
import CopyButton from "../../components/clipboard/CopyButton";

const initialState: GridInitialState = {
  columns: {
    columnVisibilityModel: {
      viewId: false,
    },
  },
};

export type ViewAction = "Create" | "Edit";

export default function ViewList() {
  const showAlert = useAlert();

  const { customerId } = useParams();
  const { isLoading, data } = useViews(customerId!);
  const { data: roles, isLoading: isRolesLoading } = useGetRoles(customerId);
  const createViewMutation = useCreateView();
  const updateViewMutation = useUpdateView();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [views, setViews] = useState<View[]>([]);
  const [action, setAction] = useState<ViewAction>("Create");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<ViewFormValues | undefined>(
    undefined
  );

  useEffect(() => {
    const views = data;
    setViews(views ?? []);
  }, [data, showAlert]);

  const createView = async (data: ViewFormValues) => {
    const viewData = {
      roleId: data.role!.roleId,
      ...data,
    };
    try {
      const { view, errors } = await createViewMutation.mutateAsync({
        customerId: customerId!,
        ...viewData,
      });
      if (!view) {
        const errorMap = (errors as any)?.errors as Map<string, string[]>;
        const generalErrorMessages =
          errorMap?.get("generalErrors")?.join(", ") ??
          "Failed to Create View.";
        showAlert(generalErrorMessages, "error");
        return;
      }

      setViews((prevViews: View[]) => {
        return [...prevViews, view];
      });
      showAlert("View created successfully.", "success");
      setIsOpen(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      } else {
        showAlert(ErrorMessage.UNKNOWN, "error");
      }
    }
  };

  const updateView = async (data: ViewFormValues) => {
    try {
      const viewData = {
        roleId: data.role!.roleId,
        ...data,
      };
      const { view, errors } = await updateViewMutation.mutateAsync({
        customerId: customerId!,
        viewId: selectedRowId!,
        ...viewData,
      });
      if (!view) {
        const errorMap = (errors as any)?.errors as Map<string, string[]>;
        const generalErrorMessages =
          errorMap?.get("generalErrors")?.join(", ") ??
          "Failed to Create View.";
        showAlert(generalErrorMessages, "error");
        return;
      }
      setViews((prevViews: View[]) => {
        return [...prevViews.filter((s) => s.viewId !== view.viewId), view];
      });
      showAlert("View updated successfully.", "success");
      setIsOpen(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      } else {
        showAlert(ErrorMessage.UNKNOWN, "error");
      }
    }
  };

  const columns: DataTableColDef[] = [
    {
      field: "name",
      headerName: "Name",
      type: "string",
      minWidth: 200,
      maxWidth: 325,
    },
    {
      field: "viewId",
      headerName: "View ID",
      minWidth: 300,
      maxWidth: 325,
    },
    {
      field: "roleId",
      headerName: "Role",
      renderCell: (params) => (
        <RoleNameDisplay customerId={customerId} roleId={params.value} />
      ),
      minWidth: 150,
      maxWidth: 350,
    },

    {
      field: "description",
      headerName: "Description",
      type: "string",
      minWidth: 300,
      maxWidth: 325,
    },
    {
      field: "retentionDate",
      headerName: "Expiration Date",
      type: "dateTime",
      valueFormatter: (value: number) =>
        value ? new Date(value * 1000).toLocaleString() : "",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      showMenuOnHover: false,
      filterable: false,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Open in New Window">
              <IconButton
                className="w-8 h-8"
                onClick={() => handleOpenWindow(params.row.viewId)}
              >
                <OpenInNewIcon color="primary"/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy Path">
              <span>
            <CopyButton
              textToCopy={getViewUrl(params.row.viewId)}
              isVisible={true}
              fontSize={"21px"}
            />
            </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const getViewUrl = (viewId: string) => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/customers/${customerId}/views/${viewId}`;
  };

  const handleOpenWindow = (viewId: string) => {
    try {
      const path = getViewUrl(viewId);
      window.open(path, "_blank");
    } catch (error) {
      showAlert("Error opening new window", "error");
    }
  };

  const getRowId = (row: View) => {
    return row.viewId;
  };

  return (
    <div className="h-full flex flex-col">
      <ResultsPage
        permissionResource="views"
        tableId="views"
        entityName="View"
        columns={columns}
        rows={views}
        getRowId={getRowId}
        initialState={initialState}
        loading={isLoading}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        setCreateFormOpen={(open: boolean) => {
          setSelectedRowId(null);
          setAction("Create");
          setIsOpen(open);
          setFormValues(undefined);
        }}
        setEditFormOpen={(open: boolean) => {
          const selectedView = views.find(
            (view) => getRowId(view) === selectedRowId
          );
          if (selectedView) {
            setFormValues({
              role: roles?.find((role) => selectedView.roleId === role.roleId)!,
              name: selectedView.name,
              description: selectedView.description,
              retentionDate: selectedView.retentionDate ?? undefined,
            });
          }
          setAction("Edit");
          setIsOpen(open);
        }}
        setDeleteDialogOpen={setIsDeleteDialogOpen}
      />
      <ViewDialog
        action={action}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        viewId={selectedRowId!}
        createView={createView}
        updateView={updateView}
        formValues={formValues}
        isRolesLoading={isRolesLoading}
      />
      <DeleteViewDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
      />
    </div>
  );
}
