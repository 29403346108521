import { postApi, putApi } from "./helpers/apiMethods";
import ApiErrors from "./helpers/errorHandling";
import { z } from "zod";
import { StorageType } from "../types/external-storage/StorageType";

const path = "configure-external-storage";

export const awsKeysSchema = z.object({
  SiteId: z.string().min(1, "Site ID is required"),
  StorageType: z.literal(StorageType.ExternalAmazonS3),
  AWSAccessKeys: z.object({
    AccessKey: z.string().min(1, "Access Key is required"),
    SecretKey: z.string().min(1, "Secret Key is required"),
    Region: z.string().min(1, "Region is required"),
    AWSBucketName: z.string().min(1, "Bucket Name is required."),
    UrlPrefix: z.string().optional(),
  }),
});

export const awsCrossAccountSchema = z.object({
  SiteId: z.string().min(1, "Site ID is required"),
  StorageType: z.literal(StorageType.ExternalAmazonS3),
  AWSCrossAccount: z.object({
    RoleArn: z.string().min(1, "Access Key is required"),
    AWSBucketName: z.string().min(1, "Bucket Name is required."),
    Region: z.string().min(1, "Region is required"),
    UrlPrefix: z.string().optional(),
    ExternalId: z.string().optional(),
  }),
});

export const azureSchema = z.object({
  SiteId: z.string().min(1, "Site ID is required"),
  region: z.string().min(1, "Region is required"),
  storageType: z.literal(StorageType.AzureBlob),
  accountName: z.string().min(1, "Account Name is required"),
  accountKey: z.string().min(1, "Account Key is required"),
  containerName: z.string().min(1, "Container Name is required."),
});

export const gcpSchema = z.object({
  SiteId: z.string().min(1, "Site ID is required"),
  StorageType: z.literal(StorageType.GCPCloud),
  GCPServiceAccountFile: z.object({
    gcpBucketName: z.string().min(1, "Bucket Name is required."),
    serviceAccountKeyFile: z.string().refine(
      (val) => {
        try {
          JSON.parse(val);
          return true;
        } catch (error) {
          return false;
        }
      },
      {
        message:
          "Invalid JSON format or structure. Please provide the full JSON key.",
      }
    ),
  }),
});

export type AWSCrossAccountRequest = z.infer<typeof awsCrossAccountSchema>;
export type GCPConfigureRequest = z.infer<typeof gcpSchema>;
export type AzureConfigureRequest = z.infer<typeof azureSchema>;
export type AWSKeysRequest = z.infer<typeof awsKeysSchema>;

export type CreateStorageConfigurationResponse = {
  message?: string;
  errors: ApiErrors;
};

export const createConfigureStorage = async <
  TRequest extends { customerId: string; siteId: string }
>({
  customerId,
  siteId,
  ...request
}: TRequest): Promise<CreateStorageConfigurationResponse> => {
  try {
    return await tryConfigureStorage({ customerId, siteId, ...request });
  } catch (error: unknown) {
    console.error("Error occurred configuring external storage, ", error);
    throw new Error("Unable to configure external storage.");
  }
};

export const tryConfigureStorage = async <
  TRequest extends { customerId: string; siteId: string }
>({
  customerId,
  siteId,
  ...request
}: TRequest): Promise<CreateStorageConfigurationResponse> => {
  const response = await postApi<CreateStorageConfigurationResponse>(
    `/v1/customers/${customerId}/${path}`,
    request
  );
  if (!response.data) {
    throw new Error("Error configuring external storage with credentials.");
  }
  return response.data;
};

/// CREDENTIALS ///

export const gcpCredentialsSchema = z.object({
  SiteId: z.string().min(1, "Site ID is required"),
  GCPServiceAccountCredentials: z.object({
    ServiceAccountKeyFile: z.string().refine(
      (val) => {
        try {
          JSON.parse(val);
          return true;
        } catch (error) {
          return false;
        }
      },
      {
        message:
          "Invalid JSON format or structure. Please provide the full JSON key.",
      }
    ),
  }),
});

export const awsKeysCredentialsSchema = z.object({
  SiteId: z.string().min(1, "Site ID is required"),
  AWSKeysCredentials: z.object({
    AccessKey: z.string().min(1, "Access Key is required"),
    SecretKey: z.string().min(1, "Secret Key is required"),
  }),
});

export const awsCrossAccountCredentialsSchema = z.object({
  SiteId: z.string().min(1, "Site ID is required"),
  AWSCrossAccountCredentials: z.object({
    RoleArn: z.string().min(1, "Role ARN is required"),
    ExternalId: z.string().optional(),
  }),
});

export type GCPCredentialsRequest = z.infer<typeof gcpCredentialsSchema>;
export type AWSCrossAccountCredentialsRequest = z.infer<
  typeof awsCrossAccountCredentialsSchema
>;
export type AWSKeysCredentialsRequest = z.infer<
  typeof awsKeysCredentialsSchema
>;
export type UpdateStorageCredentialsResponse = {
  message: string;
};


export const updateStorageCredentials = async <
  TRequest extends { customerId: string; siteId: string }
>({
  customerId,
  siteId,
  ...request
}: TRequest): Promise<UpdateStorageCredentialsResponse> => {
  try {
    const response = await putApi<UpdateStorageCredentialsResponse>(
      `/v1/customers/${customerId}/${path}/update`,
      request
    );

    if (!response.data) {
      throw new Error("Unable to update credentials");
    }
    return response.data;
  } catch (error: unknown) {
    console.error("Error occurred updating credentials, ", error);
    throw new Error("Unable to update storage credentials.");
  }
};
