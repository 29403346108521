import { FormHelperText, TextField, FormGroup } from "@mui/material";
import { UseFormReturn } from "react-hook-form";

interface FormFieldProps {
  label: string;
  name: string;
  register: UseFormReturn<any>["register"];
  formState: any;
  required?: boolean;
  type?: string;
  value?: any;
  readOnly?: boolean;
  multiline?:boolean;
  minRows?:number
}

export const FormField = ({
  label,
  name,
  register,
  formState,
  required = false,
  type = "text",
  value,
  readOnly = false,
  multiline = false,
  minRows = 1
}: FormFieldProps) => (
  <FormGroup>
    <TextField
      label={label}
      type={type}
      value={value}
      required={required}
      multiline={multiline}
      minRows={minRows}
      fullWidth
      InputProps={{
        readOnly: readOnly,
        style: { backgroundColor: readOnly ? "#f5f5f5" : "white" },
      }}
      {...register(name)}
      error={!!formState.errors[name]}
    />
    <FormHelperText error={!!formState.errors[name]?.message}>
      {formState.errors[name]?.message}
    </FormHelperText>
  </FormGroup>
);
