export interface Transcription {
  results: {
    audio_segments: TranscriptionSegment[];
  };
}

export interface TranscriptionSegment {
  id: number;
  transcript: string;
  start_time: string;
  end_time: string;
}

export class TranscriptionReader {
  private _blocks: Record<number, TranscriptionBlock> = {};

  public constructor(transcript: Transcription) {
    this.process(transcript);
  }

  private process(transcript: Transcription): void {
    for (const segment of transcript.results.audio_segments) {
      this._blocks[segment.id] = new TranscriptionBlock(
        segment.id,
        Number(segment.start_time ?? 0),
        Number(segment.end_time ?? 0),
        segment.transcript
      );
    }
  }

  public getBlockAtTimeSeconds(seconds: number): TranscriptionBlock | null {
    for (const blockId in this.blocks) {
      const block = this.blocks[Number(blockId)];
      if (seconds >= block.startSeconds && seconds < block.endSeconds) {
        return block;
      }
    }
    return null;
  }

  public getTimeOfBlock(blockId: number): number | null {
    if (blockId in this._blocks) {
      return this._blocks[blockId].startSeconds;
    }
    return null;
  }

  public get blocks(): TranscriptionBlock[] {
    return Object.values(this._blocks).sort(
      (a, b) => a.startSeconds - b.startSeconds
    );
  }
}

export class TranscriptionBlock {
  public readonly id: number;
  public readonly startSeconds: number;
  public readonly endSeconds: number;
  public readonly text: string;

  constructor(
    id: number,
    startSeconds: number,
    endSeconds: number,
    text: string
  ) {
    this.id = id;
    this.startSeconds = startSeconds;
    this.endSeconds = endSeconds;
    this.text = text;
  }
}
