import Button from "@mui/material/Button";
import { useTranscribe } from "../../hooks/useTranscription";
import { Typography } from "@mui/material";
import LoadingIndicator from "../common/LoadingIndicator/LoadingIndicator";
import { TranscriptionInProgressError } from "../../api/transcription";

export function TranscribeButton({
  className = '',
  customerId,
  interactionId,
}: Readonly<TranscribeButtonProps>) {
  const { mutateAsync, error, isPending, isSuccess } = useTranscribe(customerId, interactionId);

  async function transcribe() {
    await mutateAsync();
  }

  if (error && !(error instanceof TranscriptionInProgressError)) {
    return (
      <Typography className={className} variant="body1" color="error">
        {error.message}
      </Typography>
    )
  }

  return (
    <Button
      className={className}
      variant='contained'
      onClick={transcribe}
      disabled={isPending || isSuccess}
      startIcon={isPending ? <LoadingIndicator size="1rem" /> : undefined}
    >
      {isSuccess ? 'Transcription in progress...' : 'Transcribe'}
    </Button>
  );
}

export interface TranscribeButtonProps {
  className?: string;
  customerId: string;
  interactionId: string;
}