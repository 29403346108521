import { useContext } from "react";
import ConfirmDialog from "../../../components/common/ConfirmDialog/ConfirmDialog";
import OpenableProps from "../../../helpers/openableProps";
import FieldLabelContext from "../../search/context/FieldLabelContext";
import { useFormContext } from "react-hook-form";

export interface ResetHeadersConfirmationProps extends OpenableProps {
  submit: (values: Record<string, string>) => Promise<void>;
}

const ResetHeadersConfirmation = ({
  isOpen,
  setIsOpen,
  submit,
}: ResetHeadersConfirmationProps) => {
  const { reset } = useFormContext();
  const { fieldLabels } = useContext(FieldLabelContext);

  const resetToDefault = async () => {
    const newValues: Record<string, string> = {};
    Object.entries(fieldLabels).forEach(([fieldName, fieldLabel]) => {
      newValues[fieldName] = fieldLabel.defaultName ?? fieldName ?? "";
    });
    reset(newValues);
    await submit(newValues);
    setIsOpen(false);
  };

  return (
    <ConfirmDialog
      title="Reset Headers to Default"
      action="reset headers to the default names"
      onConfirm={resetToDefault}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};

export default ResetHeadersConfirmation;
