import { useState } from "react";
import { AuthenticationType } from "../../../../types/external-storage/Authentication";
import AWSKeysCredentialsForm from "./AWSKeysCredentialsForm";
import AWSCrossAccountCredentialsForm from "./AWSCrossAccountCredentialsForm";
import AWSAuthenticationSelect, { authTypeOptions } from "../AWSAuthenticationSelect";

interface AWSAuthenticationSelectProps {
  siteId: string;
  setIsOpen: (isOpen: boolean) => void;
}

export default function UpdateAWSCredentials({
  siteId,
  setIsOpen,
}: AWSAuthenticationSelectProps) {
  const [selectedAuthType, setSelectedAuthType] = useState<AuthenticationType>(
    AuthenticationType.AWSAccessKeys
  );
 

  return (
    <AWSAuthenticationSelect
      authTypeOptions={authTypeOptions}
      selectedAuthType={selectedAuthType}
      onChange={setSelectedAuthType}
    >
      {selectedAuthType === AuthenticationType.AWSAccessKeys && (
        <AWSKeysCredentialsForm siteId={siteId} setIsOpen={setIsOpen} />
      )}
      {selectedAuthType === AuthenticationType.AWSCrossAccount && (
        <AWSCrossAccountCredentialsForm siteId={siteId} setIsOpen={setIsOpen} />
      )}
    </AWSAuthenticationSelect>
  );
}