import View from "../types/view/View";
import {
  CreateViewRequest,
  CreateViewResponse,
  UpdateViewRequest,
  UpdateViewResponse,
  DeleteViewRequest,
  deleteView,
  updateView,
  getView,
  getViews,
  createView,
  getViewInteractions,
} from "../api/view";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import {
  GetInteractionsRequest,
  GetInteractionsResponse,
} from "../api/interaction";

export const useView = (
  customerId: string | undefined,
  viewId: string | undefined
) => {
  return useQuery<View | undefined, Error>({
    queryKey: ["view", customerId, viewId],
    queryFn: () => getView(customerId!, viewId!),
    enabled: !!customerId && !!viewId,
  });
};

export const useViews = (customerId: string) => {
  return useQuery<View[], Error>({
    queryKey: ["views"],
    queryFn: () => getViews(customerId!),
    enabled: !!customerId,
  });
};

export const useViewInteractions = (
  customerId: string,
  viewId: string,
  request?: GetInteractionsRequest
) => {
  return useQuery<GetInteractionsResponse, Error>({
    queryKey: ["view-interactions"],
    queryFn: () => getViewInteractions(customerId!, viewId!, request!),
    enabled: !!customerId && !!viewId,
  });
};

export const useCreateView = () => {
  const mutationOptions: UseMutationOptions<
    CreateViewResponse,
    Error,
    CreateViewRequest
  > = {
    mutationFn: (request: CreateViewRequest) => {
      return createView(request);
    },
  };
  return useMutation(mutationOptions);
};

export const useUpdateView = () => {
  const mutationOptions: UseMutationOptions<
    UpdateViewResponse,
    Error,
    UpdateViewRequest
  > = {
    mutationKey: ["updateView"],
    mutationFn: async (request: UpdateViewRequest) => {
      return await updateView(request);
    },
  };
  return useMutation(mutationOptions);
};

export const useDeleteView = () => {
  const mutationOptions: UseMutationOptions<
    View | undefined,
    Error,
    DeleteViewRequest
  > = {
    mutationKey: ["deleteView"],
    mutationFn: (request: DeleteViewRequest) => {
      return deleteView(request);
    },
  };
  return useMutation(mutationOptions);
};
