import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormGroup,
  TextField,
} from "@mui/material";
import { type ViewAction } from "./ViewList";
import Form from "../../components/common/Form/Form";
import { SubmitHandler } from "react-hook-form";
import { z } from "zod";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";
import RolesSelect from "../../components/roles/RolesSelect";
import { useGetRoles } from "../../hooks/useRole";
import { useParams } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import Role, { roleSchema } from "../../types/role/Role";
import ErrorMessage from "../../types/feedback/ErrorMessage";
import { useAlert } from "../../context/AlertContext";

const DEFAULT_CREATE_VALUES: ViewFormValues = {
  role: null,
  name: "",
  retentionDate: undefined,
  description: "",
};

const schema = z.object({
  role: roleSchema,
  name: z.string().min(1, "Name is required"),
  description: z.string().min(1, "Description is required"),
  retentionDate: z.number().optional(),
});

export type ViewFormValues = {
  role: Role | null;
  name: string;
  retentionDate: number | undefined;
  description: string;
};

type ViewDialogProps = {
  action: ViewAction;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  viewId: string;
  createView: (data: ViewFormValues) => Promise<void>;
  updateView: (data: ViewFormValues) => Promise<void>;
  isRolesLoading: boolean;
  formValues?: ViewFormValues;
};

export default function ViewDialog({
  action,
  isOpen,
  setIsOpen,
  viewId,
  createView,
  updateView,
  formValues,
  isRolesLoading,
}: ViewDialogProps) {
  const { customerId } = useParams();
  const { data: roles } = useGetRoles(customerId);
  const showAlert = useAlert();

  const onSubmit: SubmitHandler<ViewFormValues> = (data) => {
    try {
      if (action === "Create" && createView) {
        createView(data);
      } else if (action === "Edit" && updateView) {
        updateView(data as ViewFormValues);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      } else {
        showAlert(ErrorMessage.UNKNOWN, "error");
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialogContent-root": {
          paddingTop: "20px",
        },
        "& .MuiFormGroup-root:first-of-type": {
          marginTop: "10px",
        },
      }}
    >
      <DialogTitle>{action} View</DialogTitle>
      <DialogContent>
        <Form
          id={`${action}-view`}
          schema={schema}
          onSubmit={onSubmit}
          options={{ defaultValues: formValues || DEFAULT_CREATE_VALUES }}
        >
          {(form) => {
            const { control, formState, register, setValue, trigger } = form;
            const { isSubmitting, isDirty, errors } = formState;

            return (
              <>
                <FormGroup>
                  <TextField
                    label="Name"
                    error={!!errors.name}
                    required
                    helperText={errors.name?.message}
                    {...register("name", {
                      required: "Name is required",
                    })}
                  />
                </FormGroup>
                <RolesSelect
                  required
                  control={control}
                  roles={roles}
                  isRolesLoading={isRolesLoading}
                  error={errors.role?.message}
                  defaultValue={DEFAULT_CREATE_VALUES.role}
                  {...register("role", { required: "Role is required" })}
                />
                <FormGroup>
                  <TextField
                    required
                    label="Description"
                    error={!!errors.description}
                    helperText={errors.description?.message}
                    {...register("description", {
                      required: "Description is required",
                    })}
                  />
                </FormGroup>
                <FormGroup>
                  <DateTimePicker
                    name="retentionDate"
                    label="Expiration Date"
                    value={
                      formValues?.retentionDate
                        ? dayjs(formValues.retentionDate * 1000)
                        : null
                    }
                    onChange={(date: Dayjs | null) => {
                      if (date) {
                        const unixTimeStamp = date.unix();
                        setValue("retentionDate", unixTimeStamp, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                        trigger("retentionDate");
                      }
                    }}
                  />
                </FormGroup>
                <DialogActions>
                  <Button
                    variant="outlined"
                    disabled={isSubmitting}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting || formState.isLoading || !isDirty}
                    variant="contained"
                  >
                    {isSubmitting ? <LoadingIndicator /> : "Save"}
                  </Button>
                </DialogActions>
              </>
            );
          }}
        </Form>
      </DialogContent>
    </Dialog>
  );
}
