import { useCreateExternalStorage } from "../../../../hooks/useExternalStorage";
import { UseFormReturn } from "react-hook-form";
import { StorageType } from "../../../../types/external-storage/StorageType";
import { GCPConfigureRequest, gcpSchema } from "../../../../api/externalStorage";
import StorageForm from "../update-credentials/StorageForm";
import { FormField } from "../FormField";

interface ConfigureGCPStorageFormProps {
  storageType: StorageType.GCPCloud;
  id: string;
  siteId: string;
  setIsOpen: (isOpen: boolean) => void;
}

export default function ConfigureGCPStorageForm({
  storageType,
  id,
  siteId,
  setIsOpen,
  ...props
}: ConfigureGCPStorageFormProps) {

  const mutationHook = useCreateExternalStorage;
  interface FormFieldsProps {
    formState: UseFormReturn<GCPConfigureRequest>["formState"];
    register: UseFormReturn<GCPConfigureRequest>["register"];
  }

  const handleGcpFormData =  (data: GCPConfigureRequest) => {
      const parsedAccessKey = JSON.parse(data.GCPServiceAccountFile.serviceAccountKeyFile);
      data.GCPServiceAccountFile.serviceAccountKeyFile = parsedAccessKey;
      return data; 
    };

  const FormFields = ({ register, formState }: FormFieldsProps) => {
    return (
      <>
      <FormField label="Bucket Name" name="GCPServiceAccountFile.gcpBucketName" register={register} formState={formState} required />
      <FormField label="Access Key (JSON)" name="GCPServiceAccountFile.serviceAccountKeyFile" register={register} formState={formState} required multiline minRows={6}/>
      </>
    );
  };

  return (
    <div {...props}>
      <StorageForm
        id={id}
        siteId={siteId}
        schema={gcpSchema}
        mutationHook={mutationHook}
        requestProcessor={handleGcpFormData}
        setIsOpen={setIsOpen}
        options={{
          defaultValues: {
            SiteId: siteId,
            StorageType: storageType as StorageType.GCPCloud,
            GCPServiceAccountFile: {
              gcpBucketName: "",
              serviceAccountKeyFile: "",
            },
          },
        }}
      >
         {(form) => {
        const {register, formState} = form;
          return <FormFields register={register} formState={formState} />;
        }}
      </StorageForm>
    </div>
  );
}
