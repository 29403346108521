import Drawer from "@mui/material/Drawer";
import { Box, LinearProgress, Stack, Tooltip, Typography } from "@mui/material";
import { useInteractionMetadata } from "../../hooks/useInteraction";
import { useCallback, useContext } from "react";
import {
  canTranscribe,
  Codec,
  InteractionType,
  normalizeMediaType,
} from "../../types/interaction/Interaction";
import FieldLabelContext from "../../pages/search/context/FieldLabelContext";
import MediaDisplaySelector from "./MediaDisplaySelector";
import ClosePlayerButton from "./ButtonsAndControls/ClosePlayerButton";
import HeaderControls from "./ButtonsAndControls/ChatAndEmailOnly/HeaderControls";
import LoadingIndicator from "../common/LoadingIndicator/LoadingIndicator";
import { useFileWithTranscodePolling } from "../../hooks/useTranscoding";
import { TranscriptionView } from "../transcription/TranscriptionView";

export const getDefaultVolume = (defaultVolume: number): number => {
  const volume = localStorage.getItem("preferredVolume");
  if (volume) {
    return Number(volume);
  }
  return defaultVolume;
};

interface MediaDrawerProps {
  mediaDrawerOpen: boolean;
  playerInteractionId: string;
  customerId: string;
  closePlayer: () => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  mediaDrawerWidth: number;
  setMediaDrawerWidth: React.Dispatch<React.SetStateAction<number>>;
  minMediaDrawerWidth: number;
  maxMediaDrawerWidth: number;
  mediaType: string | undefined;
}

export const MediaDrawer = ({
  mediaDrawerOpen,
  playerInteractionId,
  customerId,
  closePlayer,
  isPlaying,
  setIsPlaying,
  mediaDrawerWidth,
  minMediaDrawerWidth,
  maxMediaDrawerWidth,
  setMediaDrawerWidth,
  mediaType,
}: MediaDrawerProps) => {
  //const { data, isLoading } = useFileUrl(playerInteractionId, customerId);
  const { data, isLoading, isPolling } = useFileWithTranscodePolling(
    playerInteractionId,
    customerId
  );

  const { data: metadata } = useInteractionMetadata(
    customerId!,
    playerInteractionId!
  );
  const agentName = (metadata as { agentName?: string })?.agentName;
  const phoneNumber = (metadata as { phoneNumber?: string })?.phoneNumber;
  const startTime = (metadata as { startTime?: string })?.startTime;
  const endTime = (metadata as { endTime?: string })?.endTime;

  const { getFieldLabel } = useContext(FieldLabelContext);

  const handleMouseDown = () => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const newWidth =
        document.body.offsetLeft + document.body.offsetWidth - e.clientX + 20;

      if (newWidth >= minMediaDrawerWidth && newWidth <= maxMediaDrawerWidth) {
        setMediaDrawerWidth(newWidth);
        localStorage.setItem("prefMediaPlayerWidth", newWidth.toString());
      }
    },
    [minMediaDrawerWidth, maxMediaDrawerWidth, setMediaDrawerWidth]
  );

  if (!mediaType) {
    return null;
  }

  const handleOpenInNewWindow = (playerInteractionId: string) => {
    const newWindowUrl = `/customers/${customerId}/interactions/${playerInteractionId}/${mediaType}/${getFieldLabel(
      "sourceId"
    )}/${getFieldLabel("startTime")}/${getFieldLabel("endTime")}`;
    const uniqueName = `Playing-Interaction-${playerInteractionId}`;
    const windowFeatures =
      "width=700,height=800,menubar=no,toolbar=no,location=no,fullscreen=yes, status=no,scrollbars=yes,resizable=yes rel=noopener no referrer";
    window.open(
      window.location.origin + newWindowUrl,
      uniqueName,
      windowFeatures
    );
    if (window) {
      window.focus();
    }
    if (closePlayer) closePlayer();
  };

  const normalizedType = normalizeMediaType(mediaType!) as InteractionType;

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
        alignContent: "space-between",
      }}
    >
      <Drawer
        sx={{
          zIndex: 1050,
          display: "flex",
          width: mediaDrawerWidth,
          justifyItems: "center",
          justifyContent: "center",
          alignContent: "space-between",
          height: "100%",
          overflowY: "auto",
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: mediaDrawerWidth,
            boxSizing: "border-box",
            p: 3,
            paddingTop: 8,
          },
        }}
        variant="persistent"
        anchor="right"
        open={mediaDrawerOpen}
      >
        <Tooltip title="Drag to resize" followCursor>
          <Box
            onMouseDown={() => handleMouseDown()}
            sx={{
              width: "8px",
              cursor: "grab",
              padding: "4px 0 0",
              borderTop: "1px solid #ddd",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              zIndex: 100,
              backgroundColor: "#f4f7f9",
              boxShadow: 5,
              userSelect: "none",
            }}
          />
        </Tooltip>
        {isLoading ? (
          <Box sx={{ mt: 3 }}>
            <LoadingIndicator /> {/* or your preferred loader */}
          </Box>
        ) : isPolling ? (
          <Box sx={{ mt: 3 }}>
            <TranscodingInteraction />
          </Box>
        ) : (
          <Box
            className="flex flex-col"
            sx={{
              flex: 1,
              overflowY: "auto",
              padding: 2,
            }}
          >
            <div className="text-center font-bold text-xl">
              {agentName} {phoneNumber}
            </div>

            <Box className="w-full text-center flex flex-wrap justify-center pb-2">
              {startTime && new Date(startTime).toLocaleString()} {" - "}
              {endTime && new Date(endTime).toLocaleString()}
            </Box>

            {normalizedType === InteractionType.Chat ||
            normalizedType === InteractionType.Email ? (
              <HeaderControls
                isLoading={isLoading}
                closePlayer={closePlayer}
                playerInteractionId={playerInteractionId}
                handleOpenInNewWindow={handleOpenInNewWindow}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginBottom: 1,
                }}
              >
                <ClosePlayerButton closePlayer={closePlayer} />
              </Box>
            )}

            <MediaDisplaySelector
              interactionType={normalizedType}
              playerInteractionId={playerInteractionId}
              data={data}
              agentId={metadata?.agentId as string}
              isLoading={isLoading}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              mediaDrawerWidth={mediaDrawerWidth}
              handleOpenInNewWindow={handleOpenInNewWindow}
              metadata={metadata}
            />
            {canTranscribe(metadata?.codec as Codec | undefined, normalizedType, Boolean(metadata?.hasMedia)) && (
              <TranscriptionView className='mt-4' customerId={customerId} interactionId={playerInteractionId} />
            )}
          </Box>
        )}
      </Drawer>
    </Box>
  );
};

/**
 * Displays a little progress bar when transcoding
 */
function TranscodingInteraction() {
  return (
    <Stack spacing={2} alignItems="center" width="100%">
      <Typography variant="h6" color="text.secondary">
        Transcoding in progress...
      </Typography>
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    </Stack>
  );
}
