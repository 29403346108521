import Box from "@mui/material/Box";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import GridToolbarRefreshButton from "./GridToolbarRefreshButton";

export interface InteractionTableToolbarProps {
  refresh: () => void | Promise<void>;
}

const InteractionTableToolbar = ({ refresh }: InteractionTableToolbarProps) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: "Change density" } }}
      />
      <GridToolbarRefreshButton refresh={refresh} />
      <Box sx={{ flexGrow: 1 }} />
    </GridToolbarContainer>
  );
};

export default InteractionTableToolbar;
