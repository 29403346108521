import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CardContent,
} from "@mui/material";

export type TableWidgetProps = {
  widget: {
    title: string;
    data: {
      columns: string[];
      rows: string[][];
    };
  };
};

export default function TableWidget({ widget }: TableWidgetProps) {
  const { columns, rows } = widget.data;

  return (
    <TableContainer component={Paper} sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h6">{widget.title}</Typography>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column: string, index: number) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: string[], rowIndex: number) => (
              <TableRow key={rowIndex}>
                {row.map((cell: string, cellIndex: number) => (
                  <TableCell key={cellIndex}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </TableContainer>
  );
}
