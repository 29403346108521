import { Card, CardContent, Typography } from "@mui/material";
import { TextWidgetData } from "../../../types/extraction-dashboard/Widget";
import Markdown from "react-markdown";

export type TextWidgetProps = {
  widget: any;
};

export default function TextWidget({ widget }: TextWidgetProps) {
  const data = widget.data as TextWidgetData;
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Typography variant="h6">{widget.title}</Typography>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            component="div"
            color="textSecondary"
            sx={{
              justifyContent: "center",
            }}
          >
            <Markdown>{data.content}</Markdown>
          </Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
}
