import OpenableProps from "../../helpers/openableProps";
import ContactSupportDialog, {
  ContactSupportDialogProps,
} from "../../components/common/ContactSupportDialog/ContactSupportDialog";

interface DeleteViewDialogProps
  extends OpenableProps,
    ContactSupportDialogProps {}

const DeleteViewDialog = ({ ...props }: DeleteViewDialogProps) => {
  return <ContactSupportDialog {...props} action="delete this view" />;
};

export default DeleteViewDialog;
