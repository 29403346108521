import { FormControl, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AuthenticationType } from "../../../types/external-storage/Authentication";

interface AWSAuthenticationFormProps {
  authTypeOptions: { value: AuthenticationType; label: string }[];
  selectedAuthType: AuthenticationType;
  onChange: (authType: AuthenticationType) => void;
  children: React.ReactNode;
}

export const authTypeOptions = [
  { value: AuthenticationType.AWSAccessKeys, label: "AWS Access Keys" },
  { value: AuthenticationType.AWSCrossAccount, label: "AWS Cross Account" },
];

export default function AWSAuthenticationSelect({
  authTypeOptions,
  selectedAuthType,
  onChange,
  children,
}: AWSAuthenticationFormProps) {
  const handleOnChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value as AuthenticationType);
  };

  return (
    <div>
      <div className="my-6">
        <FormGroup>
          <FormControl>
            <InputLabel id="authentication-method-select">Authentication Method</InputLabel>
            <Select
              label="Authentication Method"
              labelId="storage-platform-select"
              value={selectedAuthType}
              onChange={handleOnChange}
              required
            >
              {authTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </div>
      {children}
    </div>
  );
}
