import {
  type FieldValues,
  FormProvider,
  type SubmitHandler,
  type UseFormProps,
} from "react-hook-form";
import { type ZodType, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseFormReturn, useForm } from "../../../hooks/useForm";

interface FormProps<TFormValues extends FieldValues, Schema extends ZodType> {
  onSubmit: SubmitHandler<TFormValues>;
  schema: Schema;
  className?: string;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  options?: UseFormProps<TFormValues>;
  id?: string;
}

export default function Form<
  Schema extends ZodType,
  TFormValues extends FieldValues = z.infer<Schema>
>({
  onSubmit,
  children,
  className,
  options,
  id,
  schema,
}: FormProps<TFormValues, Schema>) {
  const form = useForm({
    ...options,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  return (
    <FormProvider {...form}>
      <form
        className="flex flex-col space-y-6"
        onSubmit={form.handleSubmit(onSubmit)}
        id={id}
        noValidate
      >
        {children(form)}
      </form>
    </FormProvider>
  );
}
