import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useFileUrl } from "./useInteraction";
import { useTranscodingPolling } from "./useJobs";
import { JobState } from "../types/job/Job";

/**
 * Calls useFileUrl (like normal) but then if no url is given
 * It polls based on jobId provided and recalls useFileUrl after job is complete
 * @param playerInteractionId the interactionId of media you want
 * @param customerId current customer
 * @returns regular useFileUrl and also isLoading and isPolling to display to user
 */
export const useFileWithTranscodePolling = (
  playerInteractionId: string | undefined,
  customerId: string | undefined
) => {
  const queryClient = useQueryClient();
  const [pollingJobId, setPollingJobId] = useState<string | undefined>(
    undefined
  );

  const fileQuery = useFileUrl(playerInteractionId, customerId);
  const jobStatusQuery = useTranscodingPolling(customerId, pollingJobId);

  useEffect(() => {
    if (fileQuery.data) {
      const { temporaryUrl, transcodingJobId } = fileQuery.data;
      if (!temporaryUrl && transcodingJobId) {
        // Start polling if URL is not ready but jobId exists
        setPollingJobId(transcodingJobId);
      }
    }
  }, [fileQuery.data]);

  useEffect(() => {
    if (jobStatusQuery.data === JobState.Complete) {
      setPollingJobId(undefined); // Stop polling
      queryClient.invalidateQueries({ queryKey: ["fileUrl"] }); // Refetch the URL
    }
  }, [jobStatusQuery.data, playerInteractionId, customerId, queryClient]);

  return {
    data: fileQuery.data,
    isLoading: fileQuery.isLoading,
    isPolling: !!pollingJobId,
  };
};
