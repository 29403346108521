import { Button } from "@mui/material";
import LoadingIndicator from "../../../common/LoadingIndicator/LoadingIndicator";
import Form from "../../../common/Form/Form";
import {
  FieldValues,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { ZodType } from "zod";
import { useParams } from "react-router-dom";
import React, { useContext } from "react";
import { AlertContext } from "../../../../context/AlertContext";
import ErrorMessage from "../../../../types/feedback/ErrorMessage";
import { useQueryClient, UseMutationResult } from "@tanstack/react-query";

interface StorageFormProps<T extends FieldValues, Schema extends ZodType, TRequest extends { siteId: string; customerId: string }> {
  siteId: string;
  id: string;
  schema: Schema;
  options?: UseFormProps<T>;
  children: (methods: UseFormReturn<T>) => React.ReactNode;
  requestProcessor: (data: T) => T;
  setIsOpen: (isOpen: boolean) => void;
  mutationHook: () => UseMutationResult<any, Error, TRequest, unknown>;
}

export default function StorageForm<
  T extends FieldValues,
  Schema extends ZodType,
  TRequest extends { siteId: string; customerId: string } & FieldValues
>({
  id,
  siteId,
  schema,
  options,
  children,
  requestProcessor,
  setIsOpen,
  mutationHook,

}: StorageFormProps<T, Schema, TRequest>) {
  const { customerId } = useParams();
  const showAlert = useContext(AlertContext);
  const mutation = mutationHook();
  const queryClient = useQueryClient();

  const handleSubmit = async (data: T) => {
    if (!customerId) {
      console.error("Customer ID is missing");
      return;
    }
    try {
      const processedData = requestProcessor(data);
      const mutationVariables = {
        siteId,
        customerId,
        ...processedData,
      } as TRequest;
      
      await mutation.mutateAsync(mutationVariables);
      queryClient.invalidateQueries({queryKey: ["sites"]})
      showAlert("Credentials updated successfully.", "success");
      setIsOpen(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      } else {
        showAlert(ErrorMessage.UNKNOWN, "error");
      }
    }
  };

  return (
    <Form
      id={id}
      onSubmit={handleSubmit as SubmitHandler<T>}
      schema={schema}
      options={options}
    >
      {(form) => {
        const { isSubmitting, isDirty, isLoading } = form.formState;
        return (
          <>
            {children(form)}
            <div>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={isSubmitting || isLoading || !isDirty}
              >
                {isSubmitting ? <LoadingIndicator /> : "SAVE"}
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}
