import { z } from "zod";
import Connector, { EditDefaultValuesGetter } from "./Connector";
import {
  Control,
  FieldErrors,
  FieldPath,
  UseFormRegister,
} from "react-hook-form";
import { ConnectorWizardFormValues } from "../forms/ConnectorWizard";
import { ReactNode } from "react";
import AmazonConnectConfigForm from "../forms/AmazonConnectConfigForm";

// arn:aws:connect:{region}:{12-digit number}:instance/{guid}
const awsInstanceArnRegex =
  /^arn:aws:connect:\w+(?:-\w+)+:\d{12}:instance\/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)+$/;

// Specific IAM Role ARN Regex
const iamRoleArnRegex = /^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9+=,.@_-]+$/;

// make sure all prefixes start with /
const prefixRegex = /^$|^\/.*/;

const InvalidPrefixFormatMessage =
  "Invalid prefix format (must start with '/').";

export const amazonConnectSchema = z.object({
  SourceBucketRegion: z
    .string({
      required_error: "Source bucket region is required.",
    })
    .min(1, { message: "Source bucket region cannot be empty." }),

  AmazonConnectARN: z
    .string({
      required_error: "Amazon Connect ARN is required.",
    })
    .min(1, { message: "Amazon Connect ARN cannot be empty." })
    .regex(awsInstanceArnRegex, "Invalid AWS Instance ARN format"),

  RecordingBucketName: z
    .string({
      required_error: "Recording bucket name is required.",
    })
    .min(1, { message: "Recording bucket name cannot be empty." }),

  CallRecordingPrefix: z
    .string()
    .regex(prefixRegex, InvalidPrefixFormatMessage),

  ChatTranscriptPrefix: z
    .string()
    .regex(prefixRegex, InvalidPrefixFormatMessage),

  ScreenRecordingPrefix: z
    .string()
    .regex(prefixRegex, InvalidPrefixFormatMessage),

  CTRBucketName: z
    .string({
      required_error: "CTR bucket name is required.",
    })
    .min(1, { message: "CTR bucket name cannot be empty." }),

  CTRBucketPrefix: z
    .string({
      required_error: "CTR bucket prefix is required.",
    })
    .min(1, { message: "CTR bucket prefix cannot be empty." })
    .regex(prefixRegex, InvalidPrefixFormatMessage),

  AccessRoleARN: z
    .string({
      required_error: "Access role ARN is required.",
    })
    .min(1, { message: "Access role ARN cannot be empty." })
    .regex(iamRoleArnRegex, "Invalid Role ARN format."),
});

export type AmazonConnectFormValues = z.infer<typeof amazonConnectSchema>;

export default class AmazonConnectConnector
  implements Connector<AmazonConnectFormValues>
{
  public static readonly Type = "AmazonConnect";

  public get type(): string {
    return AmazonConnectConnector.Type;
  }

  public get defaultValues(): AmazonConnectFormValues {
    return {
      SourceBucketRegion: "",
      AmazonConnectARN: "",
      RecordingBucketName: "",
      CallRecordingPrefix: "",
      ChatTranscriptPrefix: "",
      ScreenRecordingPrefix: "",
      CTRBucketName: "",
      CTRBucketPrefix: "",
      AccessRoleARN: "",
    };
  }

  public getEditDefaultValues: EditDefaultValuesGetter<AmazonConnectFormValues> =
    (values: Partial<AmazonConnectFormValues>): AmazonConnectFormValues => {
      return {
        SourceBucketRegion: values.SourceBucketRegion ?? "",
        AmazonConnectARN: values.AmazonConnectARN ?? "",
        RecordingBucketName: values.RecordingBucketName ?? "",
        CallRecordingPrefix: values.CallRecordingPrefix ?? "",
        ChatTranscriptPrefix: values.ChatTranscriptPrefix ?? "",
        ScreenRecordingPrefix: values.ScreenRecordingPrefix ?? "",
        CTRBucketName: values.CTRBucketName ?? "",
        CTRBucketPrefix: values.CTRBucketPrefix ?? "",
        AccessRoleARN: values.AccessRoleARN ?? "",
      };
    };

  public get fields(): FieldPath<ConnectorWizardFormValues>[] {
    return [
      ...amazonConnectSchema
        .keyof()
        .options.map((key) => `${AmazonConnectConnector.Type}.${key}`),
    ];
  }

  public renderFormFields(
    register: UseFormRegister<ConnectorWizardFormValues>,
    control: Control<ConnectorWizardFormValues>,
    errors?: FieldErrors<ConnectorWizardFormValues>
  ): ReactNode {
    return (
      <AmazonConnectConfigForm
        defaultValues={this.defaultValues}
        register={register}
        control={control}
        errors={errors}
      />
    );
  }
}
