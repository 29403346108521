import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { Card, CardContent, Typography } from "@mui/material";
import { InteractionByMediaType } from "../../types/dashboard/Dashboard";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";
import { useEffect, useState } from "react";
import { CardinalDirections, PieItemIdentifier } from "@mui/x-charts";
import { useNavigate, useParams } from "react-router-dom";
import { AnchorPosition } from "@mui/x-charts/ChartsLegend/legend.types";
import { useTheme } from '@mui/material/styles';

interface MediaTypeProps {
  data: InteractionByMediaType[] | null;
  isLoading: boolean;
}

const WIDTH_THRESHOLD = 1600;

const MediaTypeAnalyticsChart = ({ data, isLoading }: MediaTypeProps) => {
  const theme = useTheme();
  const COLORS = theme.palette.customColors
    ? Object.values(theme.palette.customColors)
    : [];

  const { customerId } = useParams();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!data) return <div>No Data Preview Available</div>;

  const totalMediaInteractions = data.reduce(
    (acc, currentValue) => acc + currentValue.value,
    0
  );

  // Add an id property to each data object
  const dataWithId = data.map((item, index) => ({
    ...item,
    id: index,
  }));

  // Handle click event on pie chart, navigate to search page
  const handleItemClick = (data: PieItemIdentifier) => {
    const clickedItem = dataWithId.find((item) => item.id === data.dataIndex);

    if (clickedItem) {
      navigate(
        `/customers/${customerId}/search?interactionType=${clickedItem.label}`
      );
    }
  };

  const handleLegendClick = (index: number) => {
    const clickedItem = dataWithId.find((item) => item.id === index);

    if (clickedItem) {
      navigate(
        `/customers/${customerId}/search?interactionType=${clickedItem.label}`
      );
    }
  };

  /**
   * Sets margins and legend position based on window width
   */
  const pieMargins: CardinalDirections<number> = {
    bottom: windowWidth < WIDTH_THRESHOLD ? 75 : 0,
    right: windowWidth < WIDTH_THRESHOLD ? 0 : 175,
  };

  const legendPosition: AnchorPosition = {
    vertical: windowWidth < WIDTH_THRESHOLD ? "bottom" : "middle",
    horizontal: windowWidth < WIDTH_THRESHOLD ? "middle" : "right",
  };

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Card>
        <CardContent>
          <Typography variant="h6">Interactions by Media Type</Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PieChart
              margin={pieMargins}
              height={400}
              series={[
                {
                  data: dataWithId,
                  highlightScope: { fade: "global", highlight: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                  type: "pie",
                  cornerRadius: 3,
                  arcLabelMinAngle: 35,
                  innerRadius: "60%",
                  paddingAngle: 1,
                  arcLabel: (item) =>
                    `${Math.round(
                      (item.value / totalMediaInteractions) * 100
                    )}%`,
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: 14,
                  fontWeight: "bold",
                },
              }}
              onItemClick={(_event, data) => handleItemClick(data)}
              colors={COLORS}
              slotProps={{
                legend: {
                  position: legendPosition,
                  direction:
                    window.innerWidth < WIDTH_THRESHOLD ? "row" : "column",
                  onItemClick: (_event, _context, index) =>
                    handleLegendClick(index),
                },
              }}
            ></PieChart>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default MediaTypeAnalyticsChart;
