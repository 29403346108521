import { Box, Typography, Link } from "@mui/material";
import { StorageConfigModel } from "../../types/site/Site";
import { SUPPORT_EMAIL } from "../../common/const";
import UpdateGCPStorageCredentials from "./form/update-credentials/UpdateGCPCredentialsForm";
import { StorageType } from "../../types/external-storage/StorageType";
import UpdateAWSCredentials from "./form/update-credentials/UpdateAWSCredentials";
interface ExistingStorageConfigurationDisplayProps {
  storageConfig: StorageConfigModel;
  siteId: string;
  setIsOpen: (isOpen: boolean) => void;
  // need the auth type.... 
}
export default function ExistingStorageConfigurationDisplay({
  storageConfig,
  siteId,
  setIsOpen,
}: ExistingStorageConfigurationDisplayProps) {
  return (
    <div>
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "16px",
          backgroundColor: "#fafafa",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          Storage Platform:
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {storageConfig.storageType}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Bucket Name:
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {storageConfig.bucketName}
        </Typography>
      </Box>
      <Box
        sx={{
          paddingY: "16px",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          Update Credentials
        </Typography>
        {storageConfig.storageType === StorageType.GCPCloud && (
          <UpdateGCPStorageCredentials siteId={siteId} setIsOpen={setIsOpen} />
        )}
        {storageConfig.storageType === StorageType.ExternalAmazonS3 && (
          <UpdateAWSCredentials siteId={siteId} setIsOpen={setIsOpen}
          />
        )}
      </Box>
      <Box
        sx={{
          paddingY: "16px",
        }}
      >
        <Typography>
          To reconfigure external storage location, please contact support at{" "}
          <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
        </Typography>
      </Box>
    </div>
  );
}
