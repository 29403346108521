import {
  Typography,
  Card,
  CardContent,
  Tooltip,
  useTheme
} from "@mui/material";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
export type SpeedometerWidgetProps = {
  widget: any;
};

export default function SpeedometerWidget({ widget }: Readonly<SpeedometerWidgetProps>) {
  const { title, data } = widget;
  const { currentValue, endValue } = data;
  const progress = (currentValue / endValue) * 100; // progress as percentage
  const theme = useTheme();

   const chartOptions: ApexOptions = {
     chart: {
       type: "radialBar",
       offsetY: -10, // Adjust for center alignment
     },
     plotOptions: {
       radialBar: {
         startAngle: -135,
         endAngle: 135,
         dataLabels: {
           name: {
             fontSize: "20px",
             color: "#888",
             offsetY: 120,
           },
           value: {
             fontSize: "25px",
             color: "#333",
             offsetY: 76,
           },
         },
         hollow: {
           size: "50%", // Makes the center hollow (like a speedometer)
         },
         track: {
           background: "#e0e0e0", // Light background track
           strokeWidth: "97%",
         },
       },
     },
     fill: {
       type: "gradient",
       gradient: {
         shade: "dark",
         shadeIntensity: 0.15,
         inverseColors: false,
         opacityFrom: 1,
         opacityTo: 1,
         stops: [0, 50, 65, 91],
       },
     },
     stroke: {
       dashArray: 4,
     },
     colors: [theme.palette.customColors?.wGreen], // Color of the filled portion
     labels: ["Progress"], // Label for the chart
   };
   const chartSeries =[progress]

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Typography variant="h6">{title}</Typography>
        <Tooltip title={title} followCursor={true}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ApexCharts
            options={chartOptions}
            series={chartSeries}
            type="radialBar"
            height={350}
            />
          </CardContent>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
