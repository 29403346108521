import { useParams } from "react-router-dom";
import { useJobs } from "../../../hooks/useJobs";
import { Job, JobState, JobType } from "../../../types/job/Job";
import ErrorPage from "../../../routes/error-page";
import DataTable, {
  DataTableColDef,
} from "../../../components/common/DataTable/DataTable";
import { RowSelectorMode } from "../../../types/datatable/RowSelectorMode";
import { useMemo } from "react";
import { useBulkExportDownload } from "../../../hooks/useBulkExport";
import { Chip, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useAlert } from "../../../context/AlertContext";
import BulkExportTableToolbar from "./BulkExportTableToolbar";

/**
 * Main function to display Bulk Export Table
 * @constructor
 */
export default function BulkExport() {
  const { customerId } = useParams();

  const {
    data: jobs,
    error,
    isLoading,
    refetch,
  } = useJobs(customerId, JobType.BulkExport);

  const refresh = () => {
    refetch();
  };

  const getColumns = (jobs: Job[]): DataTableColDef[] => {
    const columns: DataTableColDef[] = [
      {
        field: "action",
        headerName: "Download",
        type: "string",
        minWidth: 150,
        renderCell: (params) => <DownloadBulkExportButton job={params.row} />,
        showMenuOnHover: false,
        sortable: false,
        hideable: false,
        filterable: false,
      },
      {
        field: "state",
        showMenuOnHover: false,
        headerName: "Status",
        minWidth: 150,
        renderCell: (params) => <JobStateChip state={params.row.state} />,
      },
      {
        field: "startTime",
        headerName: "Start Time",
        minWidth: 200,
        type: "string",
        valueFormatter: (value: string) => new Date(value).toLocaleString(),
      },
      {
        field: "endTime",
        headerName: "End Time",
        minWidth: 200,
        type: "string",
        valueFormatter: (value: string) => new Date(value).toLocaleString(),
      },
      {
        field: "recordsProcessed",
        headerName: "Records Processed",
        minWidth: 200,
      },
      { field: "jobId", headerName: "Job ID", minWidth: 150 },
    ];

    return columns;
  };

  const memoizedColumns = useMemo(() => getColumns(jobs ?? []), [jobs]);

  if (error) {
    return <ErrorPage />;
  }

  return (
    <div className="h-full flex flex-col">
      <DataTable
        refresh={refresh}
        getRowId={(row: Job) => row.jobId}
        columns={memoizedColumns}
        rows={jobs ?? []}
        loading={isLoading}
        rowSelectorMode={RowSelectorMode.NONE}
        slots={{
          toolbar: BulkExportTableToolbar,
        }}
        slotProps={{
          toolbar: {
            refresh,
          },
        }}
      />
    </div>
  );
}

/**
 * Displays an action icon that when clicked opens a new tab to the bulk export result.
 * @param job the current job to get the link for
 * @constructor
 */
const DownloadBulkExportButton = ({ job }: { job: Job }) => {
  const showAlert = useAlert();
  const {
    mutateAsync: downloadFilesAsync,
    status,
    error,
  } = useBulkExportDownload();

  const isLoading = status === "pending";

  const handleClick = async () => {
    try {
      await downloadFilesAsync({
        jobId: job.jobId,
        customerId: job.customerId,
      });
      showAlert("File downloaded successfully.", "success");
    } catch (error: unknown) {
      showAlert("Error while downloading file.", "error");
    }
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Tooltip title={"Download Bulk Export"}>
      <IconButton onClick={handleClick} disabled={isLoading} size="large">
        <Download className={error ? "text-red-500" : ""} />
      </IconButton>
    </Tooltip>
  );
};

/**
 * Displays a MUI Chip with the corresponding color and name based on JobState.
 * @param state The JobState to display
 * @returns A Chip component with the appropriate color and label
 */
function JobStateChip({ state }: { state: JobState }) {
  const getChipProps = () => {
    switch (state) {
      case JobState.InProgress:
        return { label: "In Progress", color: "primary" as const };
      case JobState.Complete:
        return { label: "Complete", color: "success" as const };
      case JobState.Error:
        return { label: "Error", color: "error" as const };
      default:
        return { label: "Unknown", color: "default" as const };
    }
  };

  return <Chip {...getChipProps()} />;
}
