import { Box, Grid } from "@mui/material";
import MediaTypeAnalyticsChart from "./MediaTypeAnalyticsChart";
import AnalyticWidget from "./AnalyticWidget";
import InteractionAnalyticsBarChart from "./InteractionAnalyticsBarChart";
import { useState, useEffect } from "react";
import { useDashboardData } from "../../hooks/useDashboardData";
import { useParams } from "react-router-dom";
import {
  InteractionByMediaType,
  InteractionsByYear,
  InteractionsByTags,
} from "../../types/dashboard/Dashboard";
import dayjs from "dayjs";
import { DeleteSweep, Pause } from "@mui/icons-material";
import TagWidget from "./TagWidget";


export const formatTime = (ms: number) => {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours}h ${minutes}m ${seconds}s`;
};

const Dashboard = () => {
  const [countLegalHold, setCountLegalHold] = useState<number | undefined>();
  const [countInteractionsExpiring, setCountInteractionsExpiring] = useState<
    number | undefined
  >();
  const [interactionsByYear, setInteractionsByYear] = useState<
    InteractionsByYear | undefined
  >();
  const { customerId } = useParams();
  const { data, isLoading } = useDashboardData(customerId);
  const [mediaTypeInteraction, setMediaTypeInteraction] = useState<
    InteractionByMediaType[] | null
  >([]);

  const [tagsData, setTagsData] = useState<InteractionsByTags[] | null>([]);
  // set up links for each analytic widget
  const legalHoldLink = `/customers/${customerId}/search?legalHold=true`;

  const today = dayjs().utc();
  const thirtyDaysOut = today.add(30, "day").utc();

  const dateParams = new URLSearchParams({
    fromRetention: today.format("YYYY-MM-DDTHH:mm:ss"),
    toRetention: thirtyDaysOut.format("YYYY-MM-DDTHH:mm:ss"),
    hasRetention: "true",
  });

  const purgingLink = `/customers/${customerId}/search?${dateParams.toString()}`;

  useEffect(() => {
    if (isLoading || !data) return;

    setCountLegalHold(data.countLegalHold);
    setCountInteractionsExpiring(data.countInteractionsExpiring);
    setMediaTypeInteraction(data.interactionByType);
    setInteractionsByYear(data.interactionsByYear);
    setTagsData(data.interactionsByTags);
  }, [isLoading, data]);

  return (
    <Box
      sx={{
        padding: 4,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <AnalyticWidget
            title="Interactions in Legal Hold"
            total={countLegalHold}
            color="secondary"
            icon={
              <Pause
                fontSize="large"
                sx={{
                  color: "white",
                }}
              />
            }
            isLoading={isLoading}
            tooltipContent="The total interactions currently in legal hold."
            linkTo={legalHoldLink}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <AnalyticWidget
            title="Purging in the Next 30 Days"
            total={countInteractionsExpiring}
            color="secondary"
            icon={
              <DeleteSweep
                fontSize="large"
                sx={{
                  color: "white",
                }}
              />
            }
            isLoading={isLoading}
            tooltipContent="Number of interactions with retention date expiring in the next 30 days"
            linkTo={purgingLink}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <TagWidget isLoading={isLoading} tagData={tagsData ?? []} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <InteractionAnalyticsBarChart
            interactionsByYear={interactionsByYear}
            isBarChartLoading={isLoading}
          />
        </Grid>
        <Grid xs={12} sm={12} item md={12} lg={4}>
          <MediaTypeAnalyticsChart
            data={mediaTypeInteraction}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
