import { createTheme } from "@mui/material/styles";
declare module "@mui/material/styles" {
  interface Palette {
    customColors?: {
      wDarkBlue:string;
      wGreen: string;
      wTurquoise:string;
      wLavender:string;
      wGrayBlue:string;
    }
  }
  interface PaletteOptions {
    customColors?: {
      wDarkBlue:string;
      wGreen: string;
      wTurquoise:string;
      wLavender:string;
      wGrayBlue: string;
    }
  }
}


export const theme = createTheme({
  palette: {
    primary: {
      main: "#17254a",
    },
    secondary: {
      main: "#919191",
      contrastText: "#fff",
    },
    customColors: {
      wDarkBlue: "#17254A",
      wGrayBlue: "#274659",
      wGreen: "#4FA947",
      wTurquoise: "#47C5E9",
      wLavender: "#8f99fb"
    }
  },
});
