import Box from "@mui/material/Box";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import GridToolbarRefreshButton from "../../search/components/table/GridToolbarRefreshButton";

export interface BulkExportTableToolbarProps {
  refresh: () => void | Promise<void>;
}

const BulkExportTableToolbar = ({ refresh }: BulkExportTableToolbarProps) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarRefreshButton refresh={refresh} />
      <Box sx={{ flexGrow: 1 }} />
    </GridToolbarContainer>
  );
};

export default BulkExportTableToolbar;
