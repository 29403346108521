import { NavigateNext, NavigateBefore, Style } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";
import { Link } from "react-router-dom";
import { useState } from "react";
import { InteractionsByTags } from "../../types/dashboard/Dashboard";
import { AnimatePresence, motion } from "framer-motion";

interface TagWidgetProps {
  isLoading: boolean;
  tagData: InteractionsByTags[];
}

const ICON = <Style fontSize="large" sx={{color:"white" }}/>;

/**
 * A widget that displays tags and the current number of interactions with each
 * @param isLoading if data from backend is still loading
 * @constructor
 */
export default function TagWidget({ isLoading, tagData }: TagWidgetProps) {
  const { customerId } = useParams();

  // track the current "page" of tags
  const [currentIndex, setCurrentIndex] = useState(0);

  //track direction of animation
  const [direction, setDirection] = useState(-1); // 1 = left, -1 = right

  if (isLoading) {
    return <LoadingTagsCard />;
  }

  if (!tagData || tagData.length === 0) {
    return <NoTagsToDisplayCard />;
  }

  // variables for the tooltip
  const tooltipContent = "Current number of interactions with given tag";
  const link = `/customers/${customerId}/search?tags=${tagData[currentIndex].name}`;

  // navigate the page
  const handlePrev = () => {
    setDirection(1);
    setCurrentIndex((prev) => (prev === 0 ? tagData.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setDirection(-1);
    setCurrentIndex((prev) => (prev === tagData.length - 1 ? 0 : prev + 1));
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02, boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)" }} // Subtle lift effect
      transition={{ type: "spring", stiffness: 150, damping: 15 }} // Softer bounce
    >
      <Card
        sx={{
          height: 125,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <ArrowBackButton onClick={handlePrev} />
        <Tooltip
          title={tooltipContent}
          followCursor={false}
          arrow
          enterDelay={500}
        >
          <Link to={link}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "100%",
              }}
            >
              <AnimatePresence mode="wait" custom={direction}>
                <motion.div
                  key={currentIndex} // Ensures animation runs when index changes
                  custom={direction} // Pass direction to animation
                  initial={{ opacity: 0, x: direction * 50 }} // Moves from left or right
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: direction * -50 }} // Moves out in the opposite direction
                  transition={{ duration: 0.2, ease: "easeInOut" }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Box display="flex" alignItems="center">
                    {IconComponent}
                    <Box>
                      <Typography variant="h6" color={"primary"}>
                        Interaction Tag: {tagData[currentIndex].name}
                      </Typography>
                      <Typography variant="h4" color="secondary">
                        {tagData[currentIndex].value.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </motion.div>
              </AnimatePresence>
            </CardContent>
          </Link>
        </Tooltip>
        <ArrowNextButton onClick={handleNext} />
      </Card>
    </motion.div>
  );
}

const IconComponent = (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 64,
      height: 64,
      borderRadius: 5,
      backgroundColor: "#4FA947",
      marginRight: 2,
      marginLeft: 5,
      color: "white"
    }}
  >
    {<Style fontSize="large" />}
  </Box>
);

// Arrow Back Button Component
const ArrowBackButton = ({ onClick }: { onClick: () => void }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      left: 5,
      top: "50%",
      transform: "translateY(-50%)",
    }}
  >
    <NavigateBefore fontSize="large" />
  </IconButton>
);

// Arrow Next Button Component
const ArrowNextButton = ({ onClick }: { onClick: () => void }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      right: 5,
      top: "50%",
      transform: "translateY(-50%)",
    }}
  >
    <NavigateNext fontSize="large" />
  </IconButton>
);

/**
 * Displays the card loading
 */
function LoadingTagsCard() {
  return (
    <Card
      sx={{
        height: 125,
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 64,
            height: 64,
            borderRadius: 5,
            backgroundColor: "#4FA947",
            marginRight: 2,
            
          }}
        >
          {ICON}
        </Box>
        <LoadingIndicator sx={{ marginLeft: 5 }} />
      </CardContent>
    </Card>
  );
}

/**
 * Displays a card informing user there is no tags to display
 * @returns
 */
function NoTagsToDisplayCard() {
  return (
    <Card sx={{ height: 125 }}>
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 64,
              height: 64,
              borderRadius: 5,
              backgroundColor: "#4FA947",
              marginRight: 2,
            }}
          >
            {ICON}
          </Box>
          <Box>
            <Typography color={"secondary"}>
              No tag data to display...
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
