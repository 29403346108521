import { createWriteStream } from "streamsaver";
import { getFileUrl } from "../api/interaction";
import { getBulkExportResult } from "../api/bulkExport";

export interface DownloadInteractionFileRequest {
  customerId: string;
  interactionId: string;
}

export interface DownLoadBulkExportFileRequest {
  customerId: string;
  jobId: string;
}

const getFileNameWithExtension = (url: string) => {
  const urlParts = url.split("?")[0];
  const fileName = urlParts.substring(urlParts.lastIndexOf("/") + 1);
  return fileName;
};

export const downloadInteractionFile = async ({
  customerId,
  interactionId,
}: DownloadInteractionFileRequest): Promise<void> => {
  const fileUrl = await getFileUrl(interactionId, customerId);
  if (!fileUrl) {
    throw new Error("Failed to get file URL.");
  }

  await downloadFromUrl(fileUrl.temporaryUrl);
};

export const downloadFromBulkExport = async ({
  customerId,
  jobId,
}: DownLoadBulkExportFileRequest): Promise<void> => {
  const fileUrl = await getBulkExportResult({ jobId, customerId });
  if (!fileUrl) {
    throw new Error("Failed to get file URL.");
  }

  await downloadFromUrl(fileUrl);
};

const downloadFromUrl = async (url: string) => {
  const response = await fetch(url);
  if (!response.body) {
    throw new Error("Failed to fetch file from URL.");
  }

  const fileName = getFileNameWithExtension(url);

  await downloadFromStream(response.body, fileName);
};

const downloadFromStream = async (
  readableStream: ReadableStream<Uint8Array>,
  fileName: string
) => {
  let done = false;
  const fileStream = createWriteStream(fileName);

  try {
    window.onunload = () => {
      writer?.abort();
    };

    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (!done) {
        event.returnValue =
          "Are you sure you want to leave? A download is in progress.";
      }
    };

    if (window.WritableStream && readableStream.pipeTo) {
      await readableStream.pipeTo(fileStream);
      console.log(`Downloaded file (${fileName}) successfully.`);
      done = true;
      return;
    }

    const writer = fileStream.getWriter();
    const reader = readableStream.getReader();
    const pump = async () => {
      const response = await reader.read();
      if (response.done) {
        writer.close();
        console.log(`Downloaded file (${fileName}) successfully.`);
        done = true;
        return;
      }
      await writer.write(response.value);
      pump();
    };
    pump();
  } catch (error: unknown) {
    console.error("Error downloading file:", error);
  }
};
