import { FormHelperText, TextField, FormGroup } from "@mui/material";
import {
  AWSKeysCredentialsRequest,
  awsKeysCredentialsSchema,
} from "../../../../api/externalStorage";
import StorageForm from "./StorageForm";
import { useUpdateStorageCredentials } from "../../../../hooks/useExternalStorage";

interface AWSKeysProps {
  siteId: string;
  setIsOpen: (isOpen: boolean) => void;
}

export default function AWSKeysCredentialsForm({
  siteId,
  setIsOpen,
}: AWSKeysProps) {
  const mutationHook = useUpdateStorageCredentials;
  const AWS_CRED_DEFAULT_VALUES = {
    SiteId: siteId,
  };

  return (
    <StorageForm
      siteId={siteId}
      id="edit-keys-credentials"
      schema={awsKeysCredentialsSchema}
      setIsOpen={setIsOpen}
      options={{
        defaultValues: AWS_CRED_DEFAULT_VALUES,
      }}
      requestProcessor={((data: AWSKeysCredentialsRequest) => data)}
      mutationHook={mutationHook}
    >
      {(form) => {
        const { register, formState } = form;
        return (
          <>
            <FormGroup>
              <TextField
                label="Access Key"
                required
                fullWidth
                {...register("AWSKeysCredentials.AccessKey")}
                error={!!formState.errors.AWSKeysCredentials?.AccessKey}
              />
              <FormHelperText
                error={
                  !!formState.errors.AWSKeysCredentials?.AccessKey?.message
                }
              >
                {formState.errors.AWSKeysCredentials?.AccessKey?.message}
              </FormHelperText>
            </FormGroup>
            <FormGroup>
              <TextField
                label="Secret Key"
                required
                type="password"
                fullWidth
                {...register("AWSKeysCredentials.SecretKey")}
                error={!!formState.errors.AWSKeysCredentials?.SecretKey}
              />
              <FormHelperText
                error={
                  !!formState.errors.AWSKeysCredentials?.SecretKey?.message
                }
              >
                {formState.errors.AWSKeysCredentials?.SecretKey?.message}
              </FormHelperText>
            </FormGroup>
          </>
        );
      }}
    </StorageForm>
  );
}
