import { FormHelperText, TextField, FormGroup } from "@mui/material";
import {
  AWSCrossAccountCredentialsRequest,
  awsCrossAccountCredentialsSchema
} from "../../../../api/externalStorage";
import StorageForm from "./StorageForm";
import { useUpdateStorageCredentials } from "../../../../hooks/useExternalStorage";

interface AWSCrossAccountProps {
  siteId:string;
  setIsOpen: (isOpen: boolean) => void;
}

export default function AWSCrossAccountCredentialsForm ({
  siteId,
  setIsOpen
}: AWSCrossAccountProps) {

  const AWS_CRED_DEFAULT_VALUES = {
    SiteId:siteId
  }
  const mutationHook = useUpdateStorageCredentials;


  return (
      <StorageForm
        siteId={siteId}
        id="edit-cross-account-credentials"
        schema={awsCrossAccountCredentialsSchema}
        setIsOpen={setIsOpen}
        options={{
          defaultValues: AWS_CRED_DEFAULT_VALUES
        }}
        requestProcessor={((data:AWSCrossAccountCredentialsRequest) => data)}
        mutationHook={mutationHook}
      >
        {(form) => {
          const { register, formState } = form;
          return (
            <>
              <FormGroup>
                <TextField
                  label="Role ARN"
                  required
                  fullWidth
                  {...register("AWSCrossAccountCredentials.RoleArn")}
                  error={!!formState.errors.AWSCrossAccountCredentials?.RoleArn}
                />
                <FormHelperText
                  error={!!formState.errors.AWSCrossAccountCredentials?.RoleArn?.message}
                >
                  {formState.errors.AWSCrossAccountCredentials?.RoleArn?.message}
                </FormHelperText>
              </FormGroup>
              <FormGroup>
                <TextField
                  label="External ID"
                  fullWidth
                  {...register("AWSCrossAccountCredentials.ExternalId")}
                  error={!!formState.errors.AWSCrossAccountCredentials?.ExternalId}
                />
                <FormHelperText
                  error={!!formState.errors.AWSCrossAccountCredentials?.ExternalId?.message}
                >
                  {formState.errors.AWSCrossAccountCredentials?.ExternalId?.message}
                </FormHelperText>
              </FormGroup>
            </>
          );
        }}
      </StorageForm>
    );
}