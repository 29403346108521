import { GridSortModel } from "@mui/x-data-grid";
import Interaction, { InteractionType } from "../types/interaction/Interaction";
import { getApi, putApi } from "./helpers/apiMethods";
import { Email } from "../types/email/email";

const path = "interactions";

export type Interactions = {
  interactions: Interaction[] | undefined;
};

export interface FieldValue {
  value: string;
  friendlyValue: string;
}

export interface GetSuggestedFieldValuesRequest {
  customerId?: string;
  fieldName: string;
  pascalFieldName: string;
  value: string | number | boolean;
}

export const getSuggestedFieldValues = async ({
  customerId,
  ...request
}: GetSuggestedFieldValuesRequest): Promise<FieldValue[]> => {
  type Response = { values: FieldValue[] };
  const response = await getApi<Response>(
    `/v1/customers/${customerId}/${path}/values`,
    {
      params: request,
    }
  );
  return response.data?.values ?? [];
};

export interface GetInteractionsRequest {
  pageSize?: number;
  pageIndex?: number;
  extension?: string;
  sourceId?: string;
  siteId?: string;
  from?: string;
  to?: string;
  fromRetention?: string;
  toRetention?: string;
  hasRetention?: boolean;
  agentName?: string;
  direction?: string;
  minDuration?: number;
  maxDuration?: number;
  phoneNumber?: string;
  dnis?: string;
  legalHold?: boolean;
  hasMedia?: boolean;
  device?: string;
  interactionId?: string;
  masterInteractionId?: string;
  group?: string;
  agentId?: string;
  skill?: string;
  sortBehavior?: GridSortModel;
  segmentId?: string;
  tags?: string[];
  viewId?: string;
  interactionType?: InteractionType;
}

export interface GetInteractionsResponse {
  interactions: Interaction[];
  nextPage: number | null;
  previousPage: number | null;
  totalPages: number;
  totalCount: number;
}

export const cleanRequest = (request: object): Record<string, unknown> => {
  const newRequest: Record<string, unknown> = {};
  Object.entries(request).forEach(([key, value]) => {
    if (value != null && value !== "") {
      newRequest[key] = value;
    }
  });
  return newRequest;
};

export const getInteractions = async (
  customerId: string | undefined,
  request: GetInteractionsRequest
): Promise<GetInteractionsResponse> => {
  const response = await getApi<GetInteractionsResponse>(
    `/v1/customers/${customerId}/${path}`,
    {
      params: cleanRequest(request),
    }
  );
  return (
    response.data ?? {
      interactions: [],
      nextPage: 1,
      previousPage: null,
      totalPages: 0,
      totalCount: 0,
    }
  );
};

export const getInteractionTags = async (
  customerId: string | undefined
): Promise<string[]> => {
  const response = await getApi<string[]>(
    `/v1/customers/${customerId}/${path}/tags`
  );
  return response.data ?? [];
};

export type FileUrlResponse = {
  temporaryUrl: string;
  transcodingJobId: string;
};

export const getFileUrl = async (
  interactionId: string,
  customerId?: string
): Promise<FileUrlResponse | undefined> => {
  const response = await getApi<FileUrlResponse>(
    `/v1/customers/${customerId}/${path}/${interactionId}/media`
  );
  return response.data;
};

export interface UpdateInteractionRequest {
  customerId: string;
  interactionId: string;
  newRetentionDate?: number | null;
  newLegalHold?: boolean;
}

export const updateInteraction = async ({
  customerId,
  interactionId,
  newRetentionDate = undefined,
  newLegalHold = undefined,
}: UpdateInteractionRequest): Promise<Interaction | undefined> => {
  const response = await putApi<Interaction>(
    `/v1/customers/${customerId}/${path}/${interactionId}`,
    {
      update: {
        retentionDate: newRetentionDate,
        legalHold: newLegalHold,
      },
    }
  );
  return response.data;
};

export interface UpdateInteractionTagsRequest {
  customerId: string;
  interactionId: string;
  newTags: string[];
}
export const updateInteractionTags = async ({
  customerId,
  interactionId,
  newTags,
}: UpdateInteractionTagsRequest): Promise<Interaction | undefined> => {
  const response = await putApi<Interaction>(
    `/v1/customers/${customerId}/${path}/${interactionId}/tags`,
    {
      tags: newTags,
    }
  );
  return response.data;
};

export interface UpdateInteractionViewsRequest {
  customerId: string;
  interactionId: string;
  newViews: string[];
}

export const updateInteractionViews = async ({
  customerId,
  interactionId,
  newViews,
}: UpdateInteractionViewsRequest): Promise<Interaction | undefined> => {
  const response = await putApi<Interaction>(
    `/v1/customers/${customerId}/${path}/${interactionId}/views`,
    {
      views: newViews,
    }
  );
  return response.data;
};

export interface GetInteractionMetadataRequest {
  customerId: string;
  interactionId: string;
}

export const getInteractionMetadata = async ({
  customerId,
  interactionId,
}: GetInteractionMetadataRequest): Promise<Record<string, unknown>> => {
  type Response = {
    interactionMetadata: string;
  };
  const response = await getApi<Response>(
    `/v1/customers/${customerId}/${path}/${interactionId}/metadata`
  );
  return JSON.parse(response.data?.interactionMetadata ?? "{}");
};

export const fetchChatTranscript = async (fileUrl: string) => {
  if (!fileUrl) {
    throw new Error("File URL is required to fetch the chat transcript.");
  }
  const response = await fetch(fileUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch transcript: ${response.statusText}`);
  }

  const data = await response.json();
  return data.Transcript.map((message: any) => ({
    absoluteTime: message.AbsoluteTime,
    content: message.Content,
    contentType: message.ContentType,
    id: message.Id,
    type: message.Type,
    participantId: message.ParticipantId,
    displayName: message.DisplayName,
    participantRole: message.ParticipantRole,
  }));
};

export const fetchEmailTranscript = async (fileUrl: string) => {
  if (!fileUrl) {
    throw new Error("File URL is required to fetch the email transcript.");
  }

  const response = await fetch(fileUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch transcript: ${response.statusText}`);
  }
  const data: Email = await response.json();
  return data;
};
