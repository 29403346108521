import { useQuery } from "@tanstack/react-query";
import { Job, JobState, JobType } from "../types/job/Job";
import { getJobs, getTranscodingJobStatus } from "../api/job";

export const useJobs = (customerId: string | undefined, jobType: JobType) => {
  return useQuery<Job[], Error>({
    queryKey: ["jobs"],
    queryFn: () => getJobs({ customerId, jobType }),
  });
};

export const useTranscodingPolling = (
  customerId: string | undefined,
  jobId: string | undefined
) => {
  return useQuery<JobState | undefined, Error>({
    queryKey: ["transcode-polling", jobId],
    queryFn: () => getTranscodingJobStatus({ customerId, jobId }),
    enabled: !!customerId && !!jobId,
    refetchInterval: 2000,
    refetchOnWindowFocus: false,
  });
};
