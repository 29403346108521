import { Paper } from "@mui/material";
import BarChart from "./charts/BarChart";
import PieChart from "./charts/PieChart";
import LineChart from "./charts/LineChart";
import { ChartWidgetData } from "../../../types/extraction-dashboard/Widget";

export type ChartWidgetProps = {
  widget: any;
};
export default function ChartWidget({ widget }: ChartWidgetProps) {
  const data = widget.data as ChartWidgetData;
  const renderChart = (widget: any) => {
    switch (data.chartType) {
      case "bar":
        return <BarChart widget={widget} />;
      case "pie":
        return <PieChart widget={widget} />;
      case "line":
        return <LineChart widget={widget} />;
    }
  };
  return <Paper sx={{ height: "100%" }}>{renderChart(widget)}</Paper>;
}
