import { useMutation } from "@tanstack/react-query";
import { createBulkExport, CreateBulkExportRequest } from "../api/bulkExport";
import {
  DownLoadBulkExportFileRequest,
  downloadFromBulkExport,
} from "../helpers/fileDownload";

export const useCreateBulkExport = () => {
  return useMutation({
    mutationKey: ["createBulkExport"],
    mutationFn: (request: CreateBulkExportRequest) => createBulkExport(request),
  });
};

export const useBulkExportDownload = () => {
  return useMutation<void, Error, DownLoadBulkExportFileRequest>({
    mutationKey: ["downloadInteractionFile"],
    mutationFn: downloadFromBulkExport,
  });
};
