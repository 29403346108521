import { useCreateExternalStorage } from "../../../../hooks/useExternalStorage";
import { UseFormReturn } from "react-hook-form";
import { StorageType } from "../../../../types/external-storage/StorageType";
import {
  AWSCrossAccountRequest,
  awsCrossAccountSchema,
} from "../../../../api/externalStorage";
import StorageForm from "../update-credentials/StorageForm";
import { FormField } from "../FormField";

interface ConfigureAWSCrossAccountStorageFormProps {
  storageType: StorageType;
  id: string;
  siteId: string;
  setIsOpen: (isOpen: boolean) => void;
}
export default function ConfigureAWSCrossAccountStorageForm({
  storageType,
  id,
  siteId,
  setIsOpen,
}: ConfigureAWSCrossAccountStorageFormProps) {
  const mutationHook = useCreateExternalStorage;

  const handleAwsData = (data: AWSCrossAccountRequest) => data;

  interface AWSKeysformFieldsProps {
    formState: UseFormReturn<AWSCrossAccountRequest>["formState"];
    register: UseFormReturn<AWSCrossAccountRequest>["register"];
  }

  const FormFields = ({ register, formState }: AWSKeysformFieldsProps) => {
    return (
      <>
        <FormField
          label="Bucket Name"
          name="AWSCrossAccount.AWSBucketName"
          register={register}
          formState={formState}
          required
        />
        <FormField
          label="Region"
          name="AWSCrossAccount.Region"
          register={register}
          formState={formState}
          required
        />
        <FormField
          label="Role ARN"
          name="AWSCrossAccount.RoleArn"
          register={register}
          formState={formState}
          required
        />
        
        <FormField
          label="External Id"
          name="AWSCrossAccount.ExternalId"
          register={register}
          formState={formState}
        />
      </>
    );
  };

  return (
    <div>
      <StorageForm
        id={id}
        siteId={siteId}
        setIsOpen={setIsOpen}
        mutationHook={mutationHook}
        requestProcessor={handleAwsData}
        schema={awsCrossAccountSchema}
        options={{
          defaultValues: {
            SiteId: siteId,
            StorageType: storageType as StorageType.ExternalAmazonS3,
            AWSCrossAccount: {
              AWSBucketName: "",
              RoleArn: "",
              Region: "",
            },
          },
        }}
      >
        {(form) => {
          const { register, formState } = form;
          return <FormFields register={register} formState={formState} />;
        }}
      </StorageForm>
    </div>
  );
}
