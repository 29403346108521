import dayjs from "dayjs";
import Site from "../site/Site";
import { InteractionType } from "../interaction/Interaction";

export type SearchFormValues = {
  site?: Site | null;
  agentName?: string;
  from?: dayjs.Dayjs | null;
  to?: dayjs.Dayjs | null;
  fromRetention?: dayjs.Dayjs | null;
  toRetention?: dayjs.Dayjs | null;
  hasRetention?: boolean | null;
  sourceId?: string;
  direction?: string | null;
  device?: string;
  extension?: string;
  minDuration?: number | null;
  maxDuration?: number | null;
  interactionId?: string;
  masterInteractionId?: string;
  phoneNumber?: string;
  legalHold?: boolean | null;
  hasMedia?: boolean | null;
  group?: string;
  agentId?: string | null;
  skill?: string;
  dnis?: string;
  segmentId?: string;
  tags?: string[];
  viewId?: string;
  interactionType?: InteractionType;
};

export const DEFAULT_SEARCH_VALUES: SearchFormValues = {
  agentName: "",
  sourceId: "",
  interactionId: "",
  masterInteractionId: "",
  device: "",
  direction: null,
  extension: "",
  from: null,
  legalHold: null,
  hasMedia: null,
  maxDuration: null,
  minDuration: null,
  phoneNumber: "",
  dnis: "",
  site: null,
  to: null,
  group: "",
  agentId: "",
  skill: "",
  segmentId: "",
  tags: [],
  viewId: "",
  fromRetention: null,
  toRetention: null,
  hasRetention: null,
  interactionType: undefined,
};
